import "./PorcentagemCategoriasHistorico.css";
import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js';
import { addMonths, subMonths, format, set } from 'date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useFinancialContext } from "../../../../../../FinancialContext";

function PorcentagemCategoriasHistorico({ incomeSpendData, type }) {
    const { hideFinances, yearSelected, monthNumber, categoriesData, renderFinanceHandler } = useFinancialContext();

    const chartRefDesktop = useRef(null);
    const chartRefSmartphone = useRef(null);
    const canvasDesktopRef = useRef(null);
    const canvasSmartphoneRef = useRef(null);
    const [monthsChart, setMonthsChart] = useState([])
    const [chartData, setChartData] = useState([{}]);

    const title = type === 'income' ? "Categorias das suas receitas" : "Categorias dos seus gastos";

    const renderDesktopChart = () => {
        const ctx = canvasDesktopRef.current.getContext('2d');

        if (chartRefDesktop.current) {
            chartRefDesktop.current.destroy();
        }

        const data = {
            labels: monthsChart,
            datasets: Object.values(chartData).map((item) => {
                if (!hideFinances) {
                    return {
                        label: item.name,
                        data: item.values,
                        backgroundColor: `${item.color}B3`
                    }
                }
                return {
                    label: item.name,
                    data: 0,
                    backgroundColor: `${item.color}B3`
                }

            })
        };

        const options = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    },
                    onHover: function (event) {
                        event.native.target.style.cursor = 'pointer';
                    },
                    onLeave: function (event) {
                        event.native.target.style.cursor = 'default';
                    }
                },
                datalabels: {
                    anchor: 'center',
                    align: 'center',
                    color: '#444',
                    formatter: (value) => {
                        return "R$ " + Math.round(value);
                    },
                    font: {
                        family: 'Jost',
                        size: 9,
                        color: '#333333'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Mês',
                        font: {
                            family: 'Jost',
                            size: 16,
                        }
                    },
                    ticks: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    }
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Valor',
                        font: {
                            family: 'Jost',
                            size: 16,
                        }
                    },
                    ticks: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    }
                },
            },
            layout: {
                padding: {
                    right: 15,
                    left: 0
                }
            },
        }



        chartRefDesktop.current = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
            plugins: [ChartDataLabels],
        });

        return () => {
            if (chartRefDesktop.current) {
                chartRefDesktop.current.destroy();
            }
        };
    }

    const renderSmartphoneChart = () => {
        const ctx = canvasSmartphoneRef.current.getContext('2d');

        if (chartRefSmartphone.current) {
            chartRefSmartphone.current.destroy();
        }

        const data = {
            labels: monthsChart,
            datasets: Object.values(chartData).map((item) => {
                if (!hideFinances) {
                    return {
                        label: item.name,
                        data: item.values,
                        backgroundColor: `${item.color}B3`
                    }
                }
                return {
                    label: item.name,
                    data: 0,
                    backgroundColor: `${item.color}B3`
                }

            })
        };

        const options = {
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    labels: {
                        font: {
                            family: 'Jost',
                            size: 14,
                        }
                    }
                },
                datalabels: {
                    anchor: 'center',
                    align: 'center',
                    color: '#444',
                    formatter: (value) => {
                        return "R$ " + Math.round(value);
                    },
                    font: {
                        family: 'Jost',
                        size: 10,
                        color: '#333333'
                    }
                }
            },
            scales: {
                x: {
                    stacked: true,
                    grid: {
                        display: false
                    },
                    title: {
                        display: true,
                        text: 'Mês',
                        font: {
                            family: 'Jost',
                            size: 16,
                        }
                    },
                    ticks: {
                        font: {
                            family: 'Jost',
                            size: 12,
                        }
                    }
                },
                y: {
                    stacked: true,
                    title: {
                        display: true,
                        text: 'Valor',
                        font: {
                            family: 'Jost',
                            size: 16,
                        }
                    },
                    ticks: {
                        font: {
                            family: 'Jost',
                            size: 12,
                        }
                    }
                },
            },
            layout: {
                padding: {
                    right: 15,
                    left: 0,
                    bottom: 10
                }
            }
        }



        chartRefSmartphone.current = new Chart(ctx, {
            type: 'bar',
            data: data,
            options: options,
            plugins: [ChartDataLabels],
        });

        return () => {
            if (chartRefSmartphone.current) {
                chartRefSmartphone.current.destroy();
            }
        };
    }

    const calculateChart = () => {
        const categories = categoriesData.filter(categorie => categorie.tipoFinanca === type);
        const lastMonth = new Date(yearSelected, monthNumber - 1, 1);
        const startMonth = subMonths(lastMonth, 6);

        const getFinanceDataInMonth = (dateToCompare) => {
            return incomeSpendData.content.map(fin => renderFinanceHandler(fin, dateToCompare))
                .filter(fin => fin !== null);
        };
        const getCategoriesWithIncomeSpend = (incomeSpendDataInMonth) => {
            const categoriesIdsIncomeSpend = incomeSpendDataInMonth.map(element => element.categoria?.id || null);
            return categories.filter(categorie => categoriesIdsIncomeSpend.includes(categorie.id));
        };
        const generateChartData = () => {
            let chartDataAux = [{}];
            for (let i = 1; i <= 6; i++) {
                const dateToCompare = addMonths(startMonth, i);
                const incomeSpendDataInMonth = getFinanceDataInMonth(dateToCompare);
                const categoriesThatHaveIncomeSpend = getCategoriesWithIncomeSpend(incomeSpendDataInMonth);

                chartDataAux = {
                    ...chartDataAux,
                    [dateToCompare]: categoriesThatHaveIncomeSpend.map(categorie => ({
                        id: categorie.id,
                        name: categorie.nomeCategoria,
                        color: categorie.categoriaCor,
                        value: incomeSpendDataInMonth.filter(finance => finance.categoria?.id === categorie.id)
                            .reduce((acc, current) => acc + current.valor, 0)
                    }))
                };
            }
            return chartDataAux;
        };

        const formatChartData = (chartDataAux) => {
            chartDataAux = Object.entries(chartDataAux)
                .map(item => {
                    if (item[0] !== "0") {
                        return {
                            date: format(new Date(item[0]), 'MM/yyyy'),
                            values: item[1]
                        };
                    }
                })
                .filter(item => item !== undefined);

            return chartDataAux;
        };

        const getMonthsChart = (chartDataAux) => {
            return Object.values(chartDataAux)
                .map(item => item.date)
        };

        const getAllCategoriesInLast6Months = (chartDataAux) => {
            let allCategoriesInLast6MonthsThatHaveIncomeSpendAux = chartDataAux
                .map(item => item.values.length > 0 ? item.values : undefined)
                .filter(item => item !== undefined)
                .flat()
                .map(item => ({
                    id: item.id, name: item.name, color: item.color
                }));

            //Exluindo cópias no array
            return allCategoriesInLast6MonthsThatHaveIncomeSpendAux.filter((item, index, self) =>
                index === self.findIndex(t => t.id === item.id)
            );
        };

        const formatFinalChartData = (allCategories, chartDataAux) => {
            return allCategories.map(item => {
                const valuesArray = chartDataAux.map(month => {
                    if (month.length === 0) return 0.0;
                    const valueInTheMonth = month.values.find(monthCategorie => monthCategorie.id === item.id);
                    return valueInTheMonth !== undefined ? valueInTheMonth.value : 0.0;
                })

                return {
                    id: item.id,
                    name: item.name,
                    color: item.color,
                    values: valuesArray
                };
            });
        };

        // Generate chart data
        let chartDataAux = generateChartData();
        chartDataAux = formatChartData(chartDataAux);
        setMonthsChart(getMonthsChart(chartDataAux));

        // Get all categories with income/spend in the last 6 months
        const allCategories = getAllCategoriesInLast6Months(chartDataAux);
        const finalChartData = formatFinalChartData(allCategories, chartDataAux);
        setChartData(finalChartData);
    };

    useEffect(() => {
        if (incomeSpendData && categoriesData) {
            calculateChart();
        }
    }, [categoriesData, incomeSpendData, yearSelected, monthNumber, type]);


    useEffect(() => {
        if (incomeSpendData && categoriesData) {
            renderDesktopChart();
            renderSmartphoneChart();
        }
    }, [chartData, hideFinances]);

    return (
        <div className='FinancialCardSummaryShadow'>
            <div className='FinancialCardSummaryContainer PorcentagemCategoriasHistoricoContainer'>
                {(incomeSpendData && categoriesData) && (
                    <>
                        <header>
                            <div>
                                <p>{title}</p>
                                <p><img src='/Images_Dashboard_Summary/EstatisticsView/infoIcon.svg' />Clique na legenda da categoria para filtrá-la</p>
                            </div>
                        </header>
                        <div>
                            <canvas ref={canvasDesktopRef} className="stackedBarChartDesktop" />
                        </div>
                        <div>
                            <canvas ref={canvasSmartphoneRef} className="stackedBarChartSmartphone" />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default PorcentagemCategoriasHistorico;
