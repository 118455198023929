import './TotalBalanceCard.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import { useState } from 'react';
import useWindowWidth from '../../../../../../useWindowWidth';

function TotalBalanceCard({setExpandTotalFinances, expandTotalFinances}) {
    const { totalValue, totalSpend, totalIncome, hideFinances, incomeData, spendData, totalIncomeReceived, totalSpendPaid, totalIncomeReceivedLeft, totalSpendPaidLeft } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [detailFinances, setDetailFinances] = useState(false);


    const handleClickDetailFinances = (e) => {
        e.stopPropagation();
        setDetailFinances(!detailFinances);
        if(!isMobile){
            setExpandTotalFinances(!expandTotalFinances)
        }
    }

    const renderLine = (color, value, text1, text2, receivedPaid, receivedPaidLeft) => {

        const dynamicStyle = {
            height: !detailFinances ? '30%' : '35%',
            borderBottom: '0.1vw  #dcdcdc solid'
        };

        return (

            <div
                style={{
                    ...dynamicStyle,
                    display: (!detailFinances && !isMobile) && 'flex'
                }}
                className='ContainerIconValueExpand'
            >
                <div>
                    <div className='circleIconTotalCard'>
                        <div style={{ backgroundColor: color }}>
                            <img src='Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg' />
                        </div>
                        <p style={{ color: color }}><span>{detailFinances && 'Total'}</span> R$ {hideFinances ? "●●" : value}</p>
                    </div>
                </div>

                {detailFinances && (
                    <div className='detailIncomeSpend'>
                        <p><span style={{ color: color }}>●</span> {text1} <span style={{ color: color }}>R$ {receivedPaid}</span></p>
                        <p><span style={{ color: color }}>●</span> {text2} <span style={{ color: color }}>R$ {receivedPaidLeft}</span></p>
                    </div>
                )}
            </div>
        )
    }

    const desktopTotal = (
        <>
            {(incomeData && spendData) && (
                <div className='desktopTotalCard sizeTotalIncomeSpend FinancialCardSummaryShadow'>
                    <div className='formatTotalIncomeSpend FinancialCardSummaryContainer'>
                        <section>
                            <div>
                                <p>Saldo do mês</p>
                                <div className='circleIconTotalCard'>
                                    <p>R$ {hideFinances ? "●●" : totalValue}</p>
                                    <div className='expandIconTotalFinances' onClick={handleClickDetailFinances}>
                                        <img src='Images_Dashboard_Summary/DefaultIcons/expandArrowIcon.svg'
                                            style={{
                                                transform: detailFinances && 'rotate(180deg)'
                                            }}
                                        />
                                    </div>
                                </div>

                            </div>

                                <>
                                    {renderLine('#489946', totalIncome,'Recebido',
                                        'A receber',
                                        hideFinances ? "●●" : totalIncomeReceived,
                                        hideFinances ? "●●" : totalIncomeReceivedLeft
                                    )}
                                </>


                                <>
                                    {renderLine('#ED4C5C', totalSpend, 'Pago',
                                        'A pagar',
                                        hideFinances ? "●●" : totalSpendPaid,
                                        hideFinances ? "●●" : totalSpendPaidLeft
                                    )}
                                </>

                        </section>
                    </div>
                </div>
            )}

        </>
    )

    const renderDetailSmartphoneLine = (text1, text2, receivedPaid, receivedPaidLeft, color) => {
        return (
            <div>
                <div>
                    <p>
                        <span
                            style={{
                                color: color
                            }}
                        >● </span>{text1}</p>
                    <p
                        style={{
                            color: color
                        }}
                    >R$ {receivedPaid}</p>
                </div>
                <div>
                    <p>
                        <span
                            style={{
                                color: color
                            }}
                        >● </span>{text2}</p>
                    <p
                        style={{
                            color: color
                        }}
                    >R$ {receivedPaidLeft}</p>
                </div>
            </div>
        )
    }

    const renderLineSmartphoneTotal = (text1, text2, receivedPaid, receivedPaidLeft, color, total) => {
        return (
            <div className='containerLineSmartphoneTotal'>
                <div>
                    <div
                        style={{
                            backgroundColor: color
                        }}
                    >
                        <img src='Images_Dashboard_Summary/DefaultIcons/moneyWhiteIcon.svg' />
                    </div>
                    <p
                        style={{
                            color: color
                        }}
                    >{detailFinances && 'Total: '}R$ {hideFinances ? "●●" : total}</p>
                </div>

                {detailFinances && renderDetailSmartphoneLine(
                    text1,
                    text2,
                    hideFinances ? "●●" : receivedPaid,
                    hideFinances ? "●●" : receivedPaidLeft,
                    color
                )}

            </div>
        )
    }

    const smartphoneTotal = (
        <>
            {(incomeData && spendData) && (
                <section className='smartphoneTotalCard'
                    style={{
                        height: !detailFinances ? '35vw' : '60vw'
                    }}
                >
                    <div onClick={handleClickDetailFinances}>
                        <div>Saldo do mês</div>
                        <div>
                            <p>R$ {hideFinances ? "●●" : totalValue}</p>
                            <img src='Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/ArrowHeaderCalendar.svg'
                                style={{
                                    transform: detailFinances && 'rotate(180deg)'
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        {renderLineSmartphoneTotal(
                            "Recebido",
                            "A receber",
                            totalIncomeReceived,
                            totalIncomeReceivedLeft,
                            "#489946",
                            totalIncome
                        )}
                        {renderLineSmartphoneTotal(
                            "Pago",
                            "A pagar",
                            totalSpendPaid,
                            totalSpendPaidLeft,
                            "#ED4C5C",
                            totalSpend
                        )}
                    </div>
                </section>
            )}
        </>
    )


    return (
        <>
            {isMobile ? smartphoneTotal : desktopTotal}
        </>

    )
}

export default TotalBalanceCard;