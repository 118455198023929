import './LPMain.css'
import FirstSection from './Section1';
import SecondSection from './Section2';
import ThirdSection from './Section3';
import FourthSection from './Section4';
import Footer from './Footer';
import useViewportHeight from '../../useViewportHeight';

function LPMain(){
    useViewportHeight(); 

    return(
        <section className='mainContainerLP'>
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <FourthSection/>
            <Footer/>
        </section>
    )
}

export default LPMain;
