import './index.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { Routes, Route } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CreateUserPage from './Components/Page 3 - NewUser';
import ErrorPage from './Components/ErrorPage';
import LPMain from './Components/Page 1 - LandingPage';
import LoginPageSunna from './Components/Page 2 - LoginComponents';
import FinancialDashboard from './Components/Page 5 - DashBoard';
import AboutUs from './Components/Page 4 - AboutUs';
import InvestimentsMainPage from './Components/Page 6 - Investimentos'
import { FinancialProvider } from './FinancialContext';

const router = createBrowserRouter([
  {
    path: "/",
    element: <LPMain />,
  },
  {
    path: "login",
    element: <LoginPageSunna />,
  },
  {
    path: "cadastrar",
    element: <CreateUserPage />,
  },
  {
    path: "dashboard",
    element: <FinancialDashboard />,
  },
  {
    path: "esqueciSenha",
    element: <LoginPageSunna componentForms={"ForgotPassword"} />,
  },
  {
    path: "sobreNos",
    element: <AboutUs />,
  },
  {
    path: "*",
    element: <ErrorPage
      title="Página não encontrada!"
      text="O erro 404 é um problema do lado do cliente que indica que a URL solicitada não pode ser encontrada no servidor."
      img="/Images_Dashboard_Summary/ErrorImages/error404.png"
    />,
  },
  {
    path: "emconstrucao",
    element: <ErrorPage
      title="Obra por aqui!"
      text="Ainda não estamos com essa página pronta. Mas fique tranquilo, que nosso time já está a todo vapor preparando algo bem legal para você!"
      img="/Images_Dashboard_Summary/ErrorImages/EmConstrucaoErrorPage.png"
    />,
  },
  {
    path:"investimentos",
    element: <InvestimentsMainPage/>
  }
]);

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FinancialProvider>
      <RouterProvider router={router}>
        <Routes>
          <Route path="/" element={<LPMain />} />
          <Route path="login" element={<LoginPageSunna />} />
          <Route path="esqueciSenha" element={<LoginPageSunna componentForms={"ForgotPassword"} />} /> 
          <Route path="cadastrar" element={<CreateUserPage />} />
          <Route path="sobreNos" element={<AboutUs />} />
          <Route path="dashboard" element={<FinancialDashboard />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="emconstrucao" element={<ErrorPage />} />
          <Route path="investimentos" element={<InvestimentsMainPage/>} />
        </Routes>
      </RouterProvider>
    </FinancialProvider>
  </React.StrictMode>
);

reportWebVitals();
