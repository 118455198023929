import './SecondSection.css'

function SecondSection() {

    const card = (img, text) => {
        return (
            <section className='mainContainerCardSectionTwo'>
                <div>
                    <img src={img} />
                </div>
                <div>
                    <p>{text}</p>
                </div>
            </section>
        )
    }

    return (
        <section className='mainContainerSectionTwo'>
            <div>
                <section>
                    <div className='textAndCardsSectionTwo'>
                        <p>Viva a simplicidade</p>
                        <p>Resumindo: <span>Faremos das finanças algo simples.</span> Então vem com a gente para revolucionarmos a relação das pessoas com o dinheiro!</p>
                        <section>
                            {card("/Images_LP/SectionTwoCardFirstImage.png", "Interface Intuitiva e Responsiva.")}
                            {card("/Images_LP/SectionTwoCardSecondImage.png", "Segurança com suas finanças.")}
                            {card("/Images_LP/SectionTwoCardThirdImage.png", "Gerenciamento inteligente e fácil.")}
                        </section>
                    </div>
                    <img src='/Images_LP/SmartPhoneSectionTwo.png' className='SmartPhoneSectionTwo' />
                </section>
            </div>
        </section>
    )
}

export default SecondSection;