import './ThirdSection.css'
import { useNavigate } from 'react-router-dom';

function ThirdSection() {
    const title = 'O melhor aplicativo de gestão financeira do Brasil';
    const subtitle = 'Crie sua conta, pois o melhor momento de organizar suas finanças, é agora!'
    const navigate = useNavigate();

    const cardData = [
        {
            img: "/Images_LP/freeAppSectionThree.png",
            title: "Gratuito",
            text: "Aqui o que você mais precisa é de graça. Então aproveite um aplicativo simples, prático e bonito, sem tirar nada do seu bolso!"
        },
        {
            img: "/Images_LP/noComplicationSectionThree.png",
            title: "Zero complicações",
            text: "Aqui, é simples e direto: controle suas finanças e investimentos com facilidade. Sem complicações, sem enfeites - apenas simplicidade e praticidade."
        },
        {
            img: "/Images_LP/secureSectionThree.png",
            title: "Segurança",
            text: "Na Sunna, a segurança é um assunto sério. Garantimos que seus dados estejam sempre protegidos, para você não ter dor de cabeça."
        },
        {
            img: "/Images_LP/integrationSectionThree.png",
            title: "Integração simples",
            text: "Aqui, acreditamos que suas finanças e investimentos devem andar de mãos dadas. E como gostamos de descomplicar as coisas, você viverá essa experiência da forma mais fácil possível."
        }
    ];

    const renderCard = (item) => {
        return (
            <div className='mainContainerCardSectionThree' key={item.title}>
                <header>
                    <img src={item.img} alt="Imagem do Card" />
                    <div>
                        <div />
                        <p>{item.title}</p>
                    </div>
                </header>
                <div>
                    <p>{item.text}</p>
                </div>
            </div>
        )
    }

    return (
        <section className='mainContainerSectionThree'>
            <div>
                <div>
                    <p id='titleSectionThree'>{title}</p>
                    <p id='subtitleSectionThree'>{subtitle}</p>
                    <button onClick={() => navigate('/cadastrar')}>Comece por aqui</button>
                </div>
            </div>
            <div>
                {cardData.map(item => renderCard(item))}
            </div>
        </section>
    )
}

export default ThirdSection;
