import './FourthSection.css'
import { useNavigate } from 'react-router-dom';

function FourthSection() {
    const navigate = useNavigate();

    return (
        <section className='mainContainerSectionFour'>
            <div/>
            <header>
                <p id='titleSectionFourLP'>Em qualquer lugar, a qualquer hora</p>
                <p id='textSectionFourLP'>
                    Acesse a Sunna de qualquer lugar, seja pelo celular, tablet ou computador, 
                    mantendo sempre a melhor experiência possível. E personalize sua jornada financeira do seu jeito, 
                    ajustando tudo da forma que quiser e facilitando ainda mais o controle dos seus investimentos e das suas finanças.
                </p>
                <div>
                    <button onClick={() => navigate('/login')}>Acesse sua conta</button>
                </div>
            </header>
            <div className='bodySectionFourImage'>
                <img src='/Images_LP/Section4ComputerImage.svg'/>
            </div>
        </section>
    )
}

export default FourthSection;