import "./LoginPageSunna.css";
import React, { useState } from 'react';
import LoginSection from "./LoginSection"
import UserForgotPasswordComponents from "./ForgotPasswordComponent/UserForgotPasswordComponents";
import Header from "../Page 1 - LandingPage/Header";
import useViewportHeight from "../../useViewportHeight";

const LoginPageSunna = ({ componentForms }) => {
    useViewportHeight(); 
    
    return (
        <div className="LoginPageSunna">
            <Header hideButton={true}/>
            <section>
                <div>
                    <img src="/Images_Login/loginImage.svg" alt="loginImage" />
                </div>
                <div>

                    {componentForms === 'ForgotPassword' ?
                    
                        <UserForgotPasswordComponents/>
                        :
                        <LoginSection/>
                    }

                </div>
            </section>
        </div>
    );
};

export default LoginPageSunna;