import './HeaderSummary.css'
import ChangeMonthComponent from '../DashboardViews/ResumoView/ResumoViewCards/ChangeMonthComponent';
import { useFinancialContext } from '../../../FinancialContext';
import { useEffect, useState } from 'react';
import useWindowWidth from '../../../useWindowWidth';

function HeaderSummary({ getTitle, viewClicked, hideHeader, handleViewClick }) {
    const { userFirstName, userProfileImage, hideFinances, setHideFinances } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [imageUrl, setImageUrl] = useState(userProfileImage ? `data:image/jpeg;base64,${userProfileImage}` : '/Images_Dashboard_Summary/Header/NoUserPicture.svg');
    useEffect(() => {
        setImageUrl(userProfileImage ? `data:image/jpeg;base64,${userProfileImage}` : '/Images_Dashboard_Summary/Header/NoUserPicture.svg')
    }, [userProfileImage])

    const headerDesktop = (
        <header className='HeaderSummaryDashboard'>
            <div className='UserDetailsImageProfile'>
                <div className='user-profile-image-overflow'>
                    <img src={imageUrl} />
                </div>
                <div >
                    <p>Boas vindas,</p>
                    <p>{userFirstName.length <= 25 ? userFirstName : `${userFirstName.slice(0, 25)}..`}.</p>
                </div>
            </div>

            <section className='header-dashboard-view'>
                <div className='title-hide-finances-container'>
                    <p>{getTitle()}</p>
                    {hideFinances ? (
                        <img src='Images_Dashboard_Summary/SummaryView/Header/closedEye.svg' onClick={() => setHideFinances(false)} />
                    ) :
                        <img src='Images_Dashboard_Summary/SummaryView/Header/openEye.svg' onClick={() => setHideFinances(true)} />
                    }
                </div>
                <ChangeMonthComponent
                    userClickedOutOfWindow={viewClicked}
                />
            </section>

        </header>
    )

    const headerSmartphone = (
        <header className='HeaderSummaryDashboardSmartphone'>
            <section>
                <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' onClick={() => handleViewClick("Finanças")}/>
                <div>
                    {hideFinances ?
                        <img src='Images_Dashboard_Summary/SummaryView/Header/closedEye.svg' onClick={() => setHideFinances(false)} />
                        :
                        <img src='Images_Dashboard_Summary/SummaryView/Header/openEye.svg' onClick={() => setHideFinances(true)} />
                    }
                    <div>
                        <img src={imageUrl} />
                    </div>
                </div>
            </section>
            <div>
                <ChangeMonthComponent
                    userClickedOutOfWindow={viewClicked}
                />
            </div>
        </header>
    )

    if (!hideHeader) {
        return (
            <>
                {isMobile ? headerSmartphone : headerDesktop}
            </>
        )
    }
}

export default HeaderSummary;