import './aboutUs.css';
import Header from '../Page 1 - LandingPage/Header';
import Section1 from './Section1';
import Section2 from './Section2';
import Section3 from './Section3';
import Section4 from './Section4';
import Section5 from './Section5';
import Footer from '../Page 1 - LandingPage/Footer';
import useViewportHeight from '../../useViewportHeight';

function AboutUs() {
  useViewportHeight(); 

  
  return (
    <div className="page-about-us">
      <Header />
      <Section1 />
      <Section2 />
      <Section3 />
      {/*<Section4 />*/}
      <Section5 />
      <Footer />
    </div>
  );
}

export default AboutUs;
