import './Footer.css'
import { useNavigate } from 'react-router-dom';

function Footer() {
    const navigate = useNavigate();

    return (
        <div className='mainContainerFooterLP'>
            <header>
                <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' />
                <p>Controle Financeiro simples com Sunna</p>
                <ul>
                    <li onClick={() => navigate("/")}>Home</li>
                    <li onClick={() => navigate("/sobrenos")}>Dúvidas</li>
                    <li onClick={() => navigate("/sobrenos")}>Sobre nós</li>
                    <li onClick={() => navigate("/sobrenos")}>Contatos</li>
                </ul>
            </header>
            <footer>
                <div>
                    <span>Copyright © SUNNA.All Rights Reserved</span>
                    <p>Política de privacidade</p>
                    <p>Política de privacidade</p>
                </div>
                <div>
                    <img src='/Images_LP/InstagramLP.png' onClick={() => window.location.href="https://www.linkedin.com/in/guilherme-leonardi-rozato-26604622a/"}/>
                    <img src='/Images_LP/XLP.png' onClick={() => window.location.href="https://www.linkedin.com/in/guilherme-leonardi-rozato-26604622a/"}/>
                    <img src='/Images_LP/LinkedinLP.png' onClick={() => window.location.href="https://www.linkedin.com/in/guilherme-leonardi-rozato-26604622a/"}/>
                </div>
            </footer>
        </div>
    )
}

export default Footer;