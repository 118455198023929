import "./Section3.css"

const Section3 = () => {
    const list = [
        {
            img: "Images_AboutUs/section3lockimg.svg",
            text: "Garantimos sua segurança"
        },
        {
            img: "Images_AboutUs/section3editimg.svg",
            text: "Personalize do seu jeito"
        },
        {
            img: "Images_AboutUs/section3pcimg.svg",
            text: "Pode ser acessado de qualquer lugar"
        },
        {
            img: "Images_AboutUs/section3likeimg.svg",
            text: "Facilidade no uso"
        }
    ]

    return (
        <section className="Section3AboutUs">
            <img src="Images_AboutUs/section3leftimage.svg" alt="leftImg"/>
            <img src="Images_AboutUs/sectionthresmartphone.svg" alt="leftImgSmartphone"/>
            <div>
                <h1>O que vamos oferecer!</h1>
                <h2>Sem mais planilhas chatas e complicadas.</h2>   
                <ul>
                {Object.values(list).map((item) => {
                    return  (
                        <li>
                            <img src={item.img} alt="listImages" className="rightSideImages"></img>
                            <p>{item.text}</p>
                        </li>
                    )
                })}
                </ul>
            </div>
        </section>
    )
}

export default Section3;