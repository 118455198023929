import './ReducedFinancialCard.css';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

function ReducedFinancialCard({name, date, value, type, hideFinances}) {

    function formatDate(dateString) {
        const date = parseISO(dateString);

        const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = utcToZonedTime(date, userTimeZone);

        const day = format(zonedDate, 'dd');
        return `Dia ${day}`;
    }

    return (
        <div className='SmallFinancialCardLine'>
            <div className='ReducedCardTextNameData dataValueReducedCard'>
                {formatDate(date)}
            </div>

            <div className='ReducedCardTextNameData nameValueReducedCard'>{name}</div>

            <div className='ReducedCardValue'  id={type}>
                {type === 'spend' ? '- ' : '+ '}
                {!hideFinances ? (
                    <>{value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</>
                ) :
                    <>R$ ●●</>
                }
            </div>
        </div>
    );
}

export default ReducedFinancialCard;
