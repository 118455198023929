import './EditProfile.css'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { format, parse, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import ErrorMessage from '../../../GenericComponents/ErrorMessage';
import { useNavigate } from 'react-router-dom';
import { useFinancialContext } from '../../../../FinancialContext';

function EditProfile() {
    const { axiosURL, setUserProfileImage } = useFinancialContext();
    const navigate = useNavigate();

    const [confirmPasswodWindow, setConfirmPasswordWindow] = useState(false);
    const [newValues, setNewValues] = useState(null);
    const [userPassword, setUserPassword] = useState(null);

    const [userImg, setUserImg] = useState(null);
    const [editProfileImage, setEditProfileImage] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [savedChanges, setSavedChanges] = useState("")

    const [notSavedChanges, setNotSavedChanges] = useState("")
    const [buttonActivated, setButtonActivated] = useState(false);

    //Objeto principal de inputs e valores Original value vai guardar o valor recebido pelo fetch, ou após a confirmação da alteração.
    const [input, setInput] = useState({
        name: {
            originalValue: "",
            input: "",
            label: "Nome completo",
            key: "name",
            size: 70
        },
        email: {
            originalValue: "",
            input: "",
            label: "E-mail",
            key: "email",
            size: 50
        },
        bornDate: {
            originalValue: "",
            input: "",
            label: "Data de nascimento",
            key: "bornDate",
            size: 10
        },
        phone: {
            originalValue: "",
            input: "",
            label: "Celular",
            key: "phone",
            size: 15
        },
        cpf: {
            originalValue: "",
            input: "",
            label: "CPF",
            key: "cpf",
            size: 14
        }
    })
    const handleCpfInput = (value) => {
        if (!value) {
            setInput((prevInputs) => ({
                ...prevInputs,
                ['cpf']: {
                    ...prevInputs['cpf'],
                    input: ""
                }
            }));
            return;
        }

        
        const rawCpf = value.replace(/\D/g, '');
        let formattedCpf = '';

        for (let i = 0; i < rawCpf.length; i++) {
            if (i === 3 || i === 6) {
                formattedCpf += '.';
            } else if (i === 9) {
                formattedCpf += '-';
            }
            formattedCpf += rawCpf[i];
        }
        setInput((prevInputs) => ({
            ...prevInputs,
            ['cpf']: {
                ...prevInputs['cpf'],
                input: formattedCpf
            }
        }));
    }
    const handleBornDateInput = (value) => {
        if (!value) {
            setInput((prevInputs) => ({
                ...prevInputs,
                ['bornDate']: {
                    ...prevInputs['bornDate'],
                    input: ""
                }
            }));
            return;
        }

        const rawBornDate = value.replace(/\D/g, '');
        let formattedBornDate = '';

        for (let i = 0; i < rawBornDate.length; i++) {
            if (i === 2 || i === 4) {
                formattedBornDate += '/';
            }

            formattedBornDate += rawBornDate[i];

        }
        setInput((prevInputs) => ({
            ...prevInputs,
            ['bornDate']: {
                ...prevInputs['bornDate'],
                input: formattedBornDate
            }
        }));
    }
    const handlePhoneInput = (value) => {
        const rawPhoneNumber = value.replace(/\D/g, '');
        let formattedPhoneNumber = '';

        for (let i = 0; i < rawPhoneNumber.length; i++) {
            if (i === 0) {
                formattedPhoneNumber += '('
            }
            else if (i === 2) {
                formattedPhoneNumber += ') '
            } else if (i === 7) {
                formattedPhoneNumber += '-'
            }

            formattedPhoneNumber += rawPhoneNumber[i];

        }
        setInput((prevInputs) => ({
            ...prevInputs,
            ['phone']: {
                ...prevInputs['phone'],
                input: formattedPhoneNumber
            }
        }));
    }
    const handleInputChange = (key, value) => {
        if (key === 'cpf') {
            handleCpfInput(value);
            return;
        }
        if (key === 'bornDate') {
            handleBornDateInput(value);
            return;
        }
        if (key === 'phone') {
            handlePhoneInput(value);
            return;
        }

        setInput((prevInputs) => ({
            ...prevInputs,
            [key]: {
                ...prevInputs[key],
                input: value
            }
        }));

    }
    const handleCpfAuthenticity = (rawCpf) => {
        if (!rawCpf) {
            return true; 
        }

        let sum = 0, cont = 10;
        let firstDigit;
        let secondDigit;

        for (let i = 0; i < 9; i++) {
            sum += cont * parseInt(rawCpf[i]);
            cont--;
        }
        let rest = sum % 11;
        if (rest < 2) {
            firstDigit = 0
        } else {
            firstDigit = 11 - rest
        }

        cont = 10; sum = 0; rest = 0;

        for (let i = 1; i < 10; i++) {
            sum += cont * parseInt(rawCpf[i])
            cont--
        }

        rest = sum % 11;
        if (rest < 2) {
            secondDigit = 0;
        } else {
            secondDigit = 11 - rest;
        }

        if (parseInt(rawCpf[9], 10) === firstDigit && parseInt(rawCpf[10], 10) === secondDigit) {
            return true
        } else {
            setErrorMessage("CPF não válido")
            return false
        }

    }

    const clearEdit = () => {
        setNewValues(null);
        setConfirmPasswordWindow(false)
        setUserPassword(null);
    }

    const valuesSetter = (data) => {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const zonedDate = data.data.bornDate ? utcToZonedTime(parseISO(data.data.bornDate), userTimezone) : null;

        handleInputChange("name", data.data.name)
        handleInputChange("email", data.data.email)
        handleInputChange("bornDate", zonedDate ? format(zonedDate, "dd/MM/yyyy") : "")
        handleInputChange("phone", data.data.phone)
        handleInputChange("cpf", data.data.cpf)

        const userProfileImage = data.data.userProfileImage;
        const imageUrl = userProfileImage ? `data:image/jpeg;base64,${userProfileImage}` : '/Images_Dashboard_Summary/Header/NoUserPicture.svg';

        setUserProfileImage(userProfileImage) //Objeto principal - que vai alterar o header
        setUserImg(imageUrl);
        setEditProfileImage(null);

        setInput(prevData => ({
            name: {
                ...prevData.name,
                originalValue: data.data.name,
            },
            email: {
                ...prevData.email,
                originalValue: data.data.email,
            },
            bornDate: {
                ...prevData.bornDate,
                originalValue: data.data.bornDate ? data.data.bornDate : "",
            },
            phone: {
                ...prevData.phone,
                originalValue: data.data.phone,
            },
            cpf: {
                ...prevData.cpf,
                originalValue: data.data.cpf ? data.data.cpf : "",
            },
        }));
    }


    const fetchUpdateData = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            if(validateNullValues()){
                setErrorMessage("Não é possível apagar valores");
                return;
            }

            const updatedValues = {
                ...newValues,
                profileImage: editProfileImage,
                password: userPassword
            }
            const response = await axios.put(`${axiosURL}/users/update-profile-data`, updatedValues, config);
            if (response.status === 200) {
                valuesSetter(response);
                setSavedChanges("Alterações salvas com sucesso!")
                setTimeout(() => {
                    setSavedChanges('');
                }, 3000);

                if (response.data.token && response.data.token !== "") { //Apenas se o usuário alterou o e-mail
                    localStorage.setItem('SnnAcss', response.data.token);
                }
                setEditProfileImage(null);
                clearEdit();
            }

        } catch (error) {
            if (error.response && error.response.status === 401) {
                setErrorMessage(error.response.data);
            } else {
                console.error('Erro desconhecido:', error);
            }
            setEditProfileImage(null);
            clearEdit();
        }
    }
    const fetchUserData = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const header = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
            const response = await axios.get(`${axiosURL}/users/get-user-profile-data`, header);
            if (response.status === 200) {
                valuesSetter(response);
            }
        } catch (error) {
            console.log(error)
        }
    }


    const handleUpdateUserData = () => {
        const updatedValues = {
            name: input.name.input,
            email: input.email.input,
            bornDate: (() => {
                if(!input.bornDate.input) return null;
                const dateString = input.bornDate.input;
                const [day, month, year] = dateString.split('/');
                return new Date(year, month - 1, day);
            })(),
            phone: input.phone.input.replace(/[^0-9]/g, ''),
            cpf: input.cpf.input && input.cpf.input.replace(/[^0-9]/g, '')
        }

        if (validateValues()) {
            if (handleCpfAuthenticity(updatedValues.cpf)) {
                setConfirmPasswordWindow(true);
                setNewValues(updatedValues)
            }
        }
    }
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const imageData = e.target.result;
            setEditProfileImage(imageData);
            setUserImg(imageData);
        };

        reader.readAsDataURL(file);
    };
    const handleEditButtonClick = () => {
        const fileInput = document.getElementById('file-input');
        fileInput.click();
    };

    const confirmPasswordWindow = () => {
        return (
            <>
                <div className='opacity-overlay-edit-profile' />
                <section className='main-container-confirm-password' onClick={(e) => e.stopPropagation()} onKeyDown={(e) => { if (e.key === 'Enter') fetchUpdateData(e) }}>
                    <p>Confirme a sua senha para alterar seus dados</p>
                    <input type='password' autoFocus onChange={(e) => setUserPassword(e.target.value)} maxLength={50} />
                    <button className='button-edit-profile' onClick={fetchUpdateData}>Confirmar</button>
                </section>
            </>
        )
    }

    //Função genérica de input
    const inputForm = (element) => {
        return (
            <div className='input-container-edit-profile'>
                <p>{element.label}</p>
                <input type="text" value={element.input} onChange={(e) => handleInputChange(element.key, e.target.value)} maxLength={element.size} />
            </div>
        )
    }

    const logout = () => {
        localStorage.removeItem("SnnAcss");
        navigate('/login');
    };

    const validateNullValues = () => {
        if(
            (input.name.originalValue && !input.name.input)
            || (input.email.originalValue && !input.email.input)
            || (input.bornDate.originalValue && !input.bornDate.input)
            || (input.cpf.originalValue && !input.cpf.input)
            || (input.phone.originalValue && !input.phone.input) 
        ){
            return true;
        }
        return false;
    }

    const validateValues = () => {
        if (input.name.originalValue !== input.name.input
            || input.email.originalValue !== input.email.input
            || ((input.bornDate.originalValue === "" && input.bornDate.input !== "")
                ||  input.bornDate.originalValue && format(parseISO(input.bornDate.originalValue), "dd-MM-yyyy").replace(/\D/g, '') !== input.bornDate.input.replace(/\D/g, ''))
            || input.cpf.originalValue.replace(/[^0-9]/g, '') !== input.cpf.input.replace(/[^0-9]/g, '')
            || input.phone.originalValue.replace(/[^0-9]/g, '') !== input.phone.input.replace(/[^0-9]/g, '')
            || editProfileImage !== null
        ) {
            return true
        }
        return false
    }

    useEffect(() => {
        fetchUserData();
    }, [])

    useEffect(() => {
        if (validateValues()) {
            setNotSavedChanges("Você tem alterações não salvas")
            setButtonActivated(true);
            return;
        }
        setNotSavedChanges("Sem alterações a serem salvas");
        setButtonActivated(false);
    }, [input, editProfileImage])

    return (
        <div className='display-flex-edit-profile'>
            <div className='main-container-edit-profile' onClick={clearEdit}>
                {confirmPasswodWindow && confirmPasswordWindow()}
                <header className='header-edit-profile'>
                    <header>
                        <p>Editar perfil</p>
                        <p onClick={logout}>Sair da conta</p>
                    </header>
                    <div className='user-picture-edit-profile'>
                        <div>
                            <img src={userImg} alt="Profile" />
                        </div>
                        <img src='/Images_Dashboard_Summary/SettingsProfile/EditProfilePicPencil.svg' id='edit-pic-button' alt='edit-pic-button' onClick={handleEditButtonClick} />
                        <input
                            type="file"
                            id="file-input"
                            accept="image/png, image/jpeg"
                            onChange={handleFileChange}
                        />
                    </div>
                </header>
                <form>
                    {inputForm(input.name)}
                    <div className='input-display-flex'>
                        {inputForm(input.email)}
                        {inputForm(input.phone)}
                    </div>
                    <div className='input-display-flex'>
                        {inputForm(input.bornDate)}
                        {inputForm(input.cpf)}
                    </div>
                </form>
                <footer className='footer-edit-profile'>
                    <p
                        style={{
                            color: (!buttonActivated && savedChanges === "") ? "#242424" //Se o não tiver alterado nada - "Sem alterações a serem salvas"
                                : savedChanges != "" ? "#489946" // A edição acabou de ocorrer e foi salva - "Alterações salvas com sucesso!"
                                    : "#B93232" // Editado mas sem ter sido salvo - "Você tem alterações não salvas"
                        }}
                    >{savedChanges != "" ? savedChanges : notSavedChanges}</p>
                    <button className='button-edit-profile'
                        disabled={!buttonActivated}
                        style={{
                            cursor: !buttonActivated && 'default',
                            opacity: !buttonActivated && '60%',
                            backgroundColor: !buttonActivated && '#242424'
                        }}
                        onClick={(e) => {
                            handleUpdateUserData();
                            e.stopPropagation(e);
                        }}>Salvar</button>
                </footer>
                <ErrorMessage
                    message={errorMessage}
                    messageSetter={setErrorMessage}
                />
            </div>
        </div>
    )
}

export default EditProfile;