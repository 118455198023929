import './CheckCodeForgotPassword.css';
import React, { useState, useEffect } from 'react';
import axios from "axios";
import Cookies from "universal-cookie";
import ChangePassword from '../ChangePassword';
import InputLoginSingIn from '../../../Page 3 - NewUser/InputLoginSingIn';
import { Link } from 'react-router-dom';
import { useFinancialContext } from '../../../../FinancialContext';

function CheckCodeForgotPassword({ selectedInput, handleInputClicked }) {
    const { axiosURL } = useFinancialContext();
    const cookies = new Cookies();

    const [errorCodeVerification, setErrorCodeVerification] = useState('');
    const [inputClicked, setInputClicked] = useState(null);

    const [code, setCode] = useState('');
    const [storedEmail, setStoredEmail] = useState('');

    const [changePassword, setChangePassword] = useState(false);

    //Função para permitir apenas números no código e limpar a mensagem de erro
    const handleCodeChange = (e) => {
        setErrorCodeVerification('');
        const rawCode = e.replace(/\D/g, '');
        setCode(rawCode);
    };

    //Função de requisição para o backend para checar o código. Se ele for válido, changePassword será true e o formulário seguirá para a alteração de senha 
    const addPost = (e) => {
        e.preventDefault();
        const data = {
            code: code,
            email: storedEmail
        }

        axios.post(`${axiosURL}/forgot-password/confirm-recovery-code`, data)
            .then(response => {
                if (response.status === 200) {
                    cookies.set("code_recovery_password_user", code);
                    setChangePassword(true);
                }
            }).catch(error => {
                if (error.response && error.response.status === 401) {
                    setErrorCodeVerification("O código informado não é válido ou está expirado.");
                } else {
                    setErrorCodeVerification("Ocorreu um erro, mas já estamos trabalhando para resolver!");
                }
            })
    };

    //UseEffect que pega o e-mail do usuário nos coockies
    useEffect(() => {
        setStoredEmail(decodeURIComponent(cookies.get("email_recovery_password_user")));
    }, []);

    if (!changePassword) {
        return (
            <form onSubmit={addPost} className='checkCodeRecoveryPassword' onKeyDown={(e) => { if (e.key === 'Enter') addPost(e) }}>
                <header>
                    <h1>Informe o código</h1>
                    <p>Enviamos um código de para o e-mail:</p><span>{storedEmail}</span>
                    <p>Informe ele aqui para seguirmos com a recuperação de senha. Caso não encontre o e-mail, procure por <span>Sunna</span> que você deve encontrá-lo.</p>
                </header>
                <section>
                    <InputLoginSingIn
                        inputType={'text'}
                        textPlaceholder={"Digite o código que te enviamos por e-mail."}
                        moveTitle={"Código"}
                        selectedInput={selectedInput}
                        clickedInput={handleInputClicked}
                        setInputClicked={setInputClicked}
                        inputClicked={inputClicked}
                        valueInputed={code}
                        valueSetter={handleCodeChange}
                        inputSize={6}
                    />
                </section>
                <footer>
                    <Link to={"/login"}>Voltar</Link>
                    <button>Confirmar</button>
                </footer>
                <p id='errorMessageCodeCheck'>{errorCodeVerification}</p>
            </form>
        )

    }

    if (changePassword) {
        return (
            <ChangePassword
                selectedInput={selectedInput}
                handleInputClicked={handleInputClicked}
            />
        )
    }
}


export default CheckCodeForgotPassword;