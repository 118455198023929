import useWindowWidth from "./useWindowWidth";

const categories = [
    {
        name: 'Receitas não fixas',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaReceitasNaoFixasIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaReceitasNaoFixasIconDropbox.svg'
    },
    {
        name: 'Receitas regulares',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaReceitasRegularesIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaReceitasRegularesIconDropbox.svg'
    },
    {
        name: 'Custos esporádicos',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosEsporadicosIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosEsporadicosIconDropbox.svg'
    },
    {
        name: 'Educação',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaEducacaoIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosComEducacaoIconDropbox.svg'
    },
    {
        name: 'Transporte',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosComTransporteIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosComTransporteIconDropbox.svg'
    },
    {
        name: 'Conforto',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosComConfortoIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosComConfortoIconDropbox.svg'
    },
    {
        name: 'Custos fixos',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosFixosIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaCustosFixosIconDropbox.svg'
    },
    {
        name: 'Investimentos',
        img: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategoriaInvestimentosIconeBranco.svg',
        imgDropbox: '/Images_Dashboard_Summary/DefaultIcons/CategoriaIcons/CategorieInvestimentosIconDropbox.svg'
    }
]

export const getCategorieIcon = (categorieName) => {
    const icon = categories.find(cat => cat.name === categorieName);
    return icon ? icon.img : null;
}

export const getCategorieDropboxIcon = (categorieName) => {
    const icon = categories.find(cat => cat.name === categorieName);
    return icon ? icon.imgDropbox : null;
}

export const getCategorieObject = (categorieName) => {
    const icon = categories.find(cat => cat.name === categorieName);
    return icon && icon;
}

export const allCategoriesColor = ["#c13737", "#c15a37", "#c18e37", "#c8b829", "#bbc829",
"#a6c829", "#83c829", "#63c829", "#29c841", "#29c873",
"#29c89b", "#29c8c0", "#29b0c8", "#2990c8", "#2969c8",
"#2941c8", "#4129c8", "#6b29c8", "#9e29c8", "#c529c8",
"#c82996", "#c8296b"
]

const banks = {
    title: `Banco do cartão`,
    defaultDisplay: (
        <>
            <img src='/Images_Dashboard_Summary/DefaultIcons/FilterIcons/CreditCardFilterIcon.svg' style={{minWidth: '20px', minHeight: '20px'}}/>
            <p>Selecionar cartão</p>
        </>
    ),
    values: [
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/BancoInter.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Banco inter</p></div>),
            findIndex: 'Banco inter',
            logo: '/Images_Dashboard_Summary/BanksLogos/BancoInter.png',
            cor: '#ff7600',
            nameInput: 'Cartão Inter'
        },
        {
            name: (<div><img src='/Images_Dashboard_Summary/BanksLogos/Nubank.png' style={{minWidth: '20px', marginLeft: '5px'}}/><p>Nubank</p></div>),
            findIndex: 'Nubank',
            logo: '/Images_Dashboard_Summary/BanksLogos/Nubank.png',
            cor: '#9b02d5',
            nameInput: 'Cartão Nubank'
        }
    ]
}

export const getAllBanks = () => {
    return banks;
}

export const getBankLogo = (name) => {
    const bank = banks.values.find(bank => bank.findIndex === name);
    return bank ? bank.logo : null;
}

export const checkBankExist = (name) => banks.values.some(bank => bank.findIndex === name);

export const getBankColor = (find) => {
    const color = banks.values.find(bank => bank.findIndex === find);
    return color ? color.cor : "#C7A630";
}


