import './InputLoginSingIn.css'

function InputLoginSingIn({ inputType, textPlaceholder, moveTitle, textAlert, errorMessage, valueInputed, valueSetter, inputKey, inputSize}) {

    return (
        <div className='mainContainerInputSingIn'>
            <label htmlFor="inputField">
                {moveTitle}
            </label>            
            <div className="containerInputSingIn">
                <input 
                    key={inputKey}
                    type={inputType} 
                    placeholder={textPlaceholder}
                    value={valueInputed}
                    onChange={(e) => {
                        valueSetter(e.target.value, inputKey);
                    }} 
                    maxLength={inputSize}
                />
            </div>
            <p className={`AlertInputSingIn ${errorMessage ? 'errorMessageLogin' : ''}`}>{errorMessage ? errorMessage : textAlert}</p>
        </div>
    )
}

export default InputLoginSingIn;
