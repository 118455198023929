import './ErrorPage.css'
import { Link } from 'react-router-dom';
import useViewportHeight from '../../useViewportHeight';

function ErrorPage({title, text, img}) {
    useViewportHeight(); 


    return (
        <main className='mainContainerErrorPage'>

            <header className='headerErrorPage'>
                <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' />
            </header>

            <section className='mainContainerBodyErrorPage'>

                <section className='mainContainerText'>
                    <div className='textErrorPageError'>
                        <div className='titlePageError'>
                            <p>Opa... <span>{title}</span></p>
                        </div>
                        <div className='textPageError'>
                            <p>{text}</p>
                        </div>
                        <div className='linkReturnErrorPage'>
                            <Link to="/dashboard">Clique aqui para voltar</Link>
                        </div>
                    </div>
                </section>

                <section className='imageErrorPageError'>
                    <img src={img} />
                </section>

            </section>
            
            <img src='/Images_Dashboard_Summary/ErrorImages/formatErrorPage.png' id='retangleBottom'/>
        </main>
    )
}
export default ErrorPage;