import "./Section2.css"

const Section2 = () => {
    return (
        <section className="Section2AboutUs">
            <img src="Images_AboutUs/section2lockimg.svg"></img>
            <h1>Tenha a segurança de suas informações</h1>
            <h2>Aqui segurança é uma assunto muito sério! Garantimos que seus dados estarão sempre protegidos.</h2>
        </section>
    )
}

export default Section2;