import './ResumoView.css'
import React, { useState } from 'react';
import ReducedFinancialCard from './ResumoViewCards/ReducedFinancialCard';
import FinanceOpenCard from './ResumoViewCards/FinanceOpenCard';
import TotalBalanceCard from './ResumoViewCards/TotalBalanceCard';
import CategoriesGraph from './ResumoViewCards/CategoriesGraphCard';
import EstatisticsSummaryCard from './ResumoViewCards/EstatisticsSummaryCard'
import { useFinancialContext } from '../../../../FinancialContext';
import useWindowWidth from '../../../../useWindowWidth';

function ResumoView({ setSelectedView, viewClicked, openFormsCreateNewFinance,
    setOpenFormsCreateNewFinance, openFullCard, setIncomeCardSelected, setSpendCardSelected, spendCardSelected, incomeCardSelected }) {

    const { incomeData, spendData, incomeCategoriesData, spendCategoriesData } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [createFinance, setCreateFinance] = useState(false);
    const [expandTotalFinances, setExpandTotalFinances] = useState(false);

    //Função para abrir o card grande, seja de gastos ou receitas - Verificado através do parâmetro da função

    //Função para fechar o card grande, seja ele de receitas ou gastos. 
    const closeFullView = async () => {
        setIncomeCardSelected(false);
        setSpendCardSelected(false);
        setCreateFinance(false);
    }

    //Função que renderiza o componente pequeno do dashboard de finanças, podendo ser Receita ou gasto, dependendo de como o chama.
    const renderShortFinancialCard = (type) => {
        return (
            <div onClick={() => openFullCard(type)} className='ShortFinancialCard'>
                <ReducedFinancialCard
                    title={type === "income" ? "Minhas receitas" : "Meus gastos"}
                    type={type}
                    setCreateFinance={setCreateFinance}
                />
            </div>
        );
    }

    //Função que renderiza o componente grande em caso de selected ser true
    const renderFullCard = (selected, financeData, categoriesData, type) => {
        return selected && financeData && (
            <div className="MainIncomeSpendFullCardClick MainIncomeSpendFullCardOverlay" onClick={() => closeFullView(false)}>
                <FinanceOpenCard
                    type={type}
                    financeData={financeData}
                    categoriesDataProps={categoriesData}
                    createFinance={createFinance}
                    viewClicked={viewClicked}
                    closeFullView={closeFullView}
                    openFormsCreateNewFinance={openFormsCreateNewFinance}
                    setOpenFormsCreateNewFinance={setOpenFormsCreateNewFinance}
                />
                <img src='/Images_Dashboard_Summary/SummaryView/OpenFinancialCard/CloseCardX.png' className='CloseCardButton' alt="Close Button"></img>
            </div>
        );
    }

    const renderCategoriesGraphCard = (type) => {
        return (
            <div className="CategoriesFinancialGraph" >
                <CategoriesGraph
                    type={type}
                    viewClicked={viewClicked}
                />
            </div>
        )
    }

    return (
        <div className='MainIncomeAndSpendPart'>
            <section className='TotalFinanceAndEstasticsCard'>
                <div className='ShortCardTotalFinance'
                    style={!isMobile ? {
                        height: expandTotalFinances ? '16vw' : '12vw'
                    } : {}}
                    >
                    <TotalBalanceCard setExpandTotalFinances={setExpandTotalFinances} expandTotalFinances={expandTotalFinances} />
                </div>
                <div className='ShortCardTotalEstatistics' onClick={() => setSelectedView('Estatísticas')}
                    style={!isMobile ? {
                        height: expandTotalFinances ? '16vw' : '20vw'
                    } : {}}
                >
                    <EstatisticsSummaryCard />
                </div>
            </section>
            <section className='ShortFinancialAndCategoriesCardsSection'>

                <div className='columnShortFinancialGraphCards'>
                    {renderShortFinancialCard("income")}
                    {renderShortFinancialCard("spend")}
                </div>
                <div className='columnShortCategoriesCards'>
                    {renderCategoriesGraphCard("receitas")}
                    {renderCategoriesGraphCard("gastos")}
                </div>
                {renderFullCard(incomeCardSelected, incomeData, incomeCategoriesData, "income")}
                {renderFullCard(spendCardSelected, spendData, spendCategoriesData, "spend")}
            </section>
            <div className='spaceSmartPhoneBottomBar' />
        </div>

    )

}

export default ResumoView;