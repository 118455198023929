import { useEffect, useState } from 'react';
import './Dropbox.css'
import useWindowWidth from '../../../../../../../useWindowWidth';

function Dropbox({ data, selectedValue, handleClick, closeFilterWindows, setCloseFilterWindows,
    textAlign, style, style2, hideArrow }) {
    const isMobile = useWindowWidth();


    const [dropboxActive, setDropboxActive] = useState(false)

    const handleClickOption = (item) => {
        handleClick(item);
        setDropboxActive(false)
    }

    const handleClickContainer = (e) => {
        e.stopPropagation();
        setDropboxActive(!dropboxActive)
        setCloseFilterWindows(data.title);
    }

    useEffect(() => {
        if (closeFilterWindows !== data.title) {
            setDropboxActive(false);
        }
    }, [closeFilterWindows])

    return (
        <div className='DropboxContainer' onClick={handleClickContainer}
            style={{
                ...style,
                borderRadius: isMobile ? (dropboxActive ? '1vw 1vw 0 0' : '1vw') : (dropboxActive ? '0.3vw 0.3vw 0 0' : '0.3vw') 
            }}
        >
            <div>
                <div>{selectedValue ? selectedValue.name : data.defaultDisplay}</div>
                {!hideArrow && <img src='Images_Dashboard_Summary/SummaryView/OpenFinancialCard/arrowdropbox.svg' alt='dropboxArrow' />}
            </div>
            {(dropboxActive) && (

                <div
                    style={style && { border: 'none' }} //Caso tenha estilo personalizado, ele tira as bordas da dropbox
                >
                    <ul>
                        {Object.values(data.values).map((item, index) => {
                            return (
                                <li
                                    key={index}
                                    style={
                                        style2
                                            ? style2
                                            : {
                                                justifyContent: textAlign,
                                                textIndent: textAlign === 'left' ? '4%' : '0%'
                                            }
                                    }
                                    onClick={() => handleClickOption(item)}
                                >
                                    {item.name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default Dropbox;