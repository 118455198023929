import './EstatisticsSummaryCard.css'
import { useState, useEffect } from 'react';
import { subMonths, format, addMonths } from 'date-fns';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useFinancialContext } from '../../../../../../FinancialContext';

function EstatisticsSummaryCard() {
    const { hideFinances, incomeData, spendData, renderFinanceHandler, yearSelected, monthNumber } = useFinancialContext();

    const [chartData, setChartData] = useState([]);
    const [hasData, setHasData] = useState(false);

    //Principal função para calculo do valor total histórico de receitas e gastos por mês - L12M
    useEffect(() => {
        if(incomeData && spendData){
            const calculateFinanceDataForMonth = (data, renderHandler, startMonth) => {
                let financeByMonth = {};
                for (let i = 1; i <= 3; i++) {
                    const dateToCompare = addMonths(startMonth, i);
                    financeByMonth[dateToCompare] = data.content.map(financeItem => {
                        const finance = renderHandler(financeItem, dateToCompare);
                        if (finance) return finance.valor;
                        return null;
                    })
                        .filter(value => value !== null)
                        .reduce((acc, value) => acc + value, 0);
                }
                return financeByMonth;
            };
    
            const formatFinanceData = (financeData) => {
                return Object.entries(financeData)
                    .map(([date, value]) => {
                        if (date !== "0") {
                            return {
                                date: format(new Date(date), 'MM/yyyy'),
                                value
                            };
                        }
                        return null;
                    })
                    .filter(item => item !== null);
            };
    
            const mergeFinanceData = (incomes, spends) => {
                return incomes.map(income => {
                    const spend = spends.find(s => s.date === income.date);
                    return {
                        date: income.date,
                        incomeValue: income.value,
                        spendValue: spend ? spend.value : 0
                    };
                });
            };
    
            const lastMonth = new Date(yearSelected, monthNumber - 1, 1); // Puxa o mês atual que o usuário está vendo
            const startMonth = subMonths(lastMonth, 3); // Mês atual menos 12 meses
    
            const incomesByMonth = calculateFinanceDataForMonth(incomeData, renderFinanceHandler, startMonth);
            const spendByMonth = calculateFinanceDataForMonth(spendData, renderFinanceHandler, startMonth);
    
            const formattedIncomes = formatFinanceData(incomesByMonth);
            const formattedSpends = formatFinanceData(spendByMonth);
    
            const finalChartData = mergeFinanceData(formattedIncomes, formattedSpends);
    
            setChartData(finalChartData);
            if (finalChartData.length > 0) setHasData(true);
        }
    }, [monthNumber, yearSelected, spendData, incomeData]);

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
                labels: {
                    font: {
                        family: 'Jost',
                        size: 13,
                    },
                    padding: 10
                }
            },
            datalabels: {
                color: '#444',
                anchor: 'end',
                align: 'top',
                offset: -6, // Ajuste o offset para mover os datalabels para baixo
                font: {
                    size: 11,
                    family: 'Jost'
                },
                formatter: (value) => {
                    return "R$" + Math.round(value); // Formata os números como string localizada
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 9,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 9,
                    }
                }
            }
        },
        layout: {
            padding: {
                right: 20,
                left: 10,
            }
        },
    };

    const data = {
        labels: chartData.map(item => item.date),
        datasets: [
            {
                label: "Receitas",
                data: !hideFinances ? chartData.map(item => item.incomeValue) : 0,
                backgroundColor: 'rgb(72, 153, 70, 0.6)',
            },
            {
                label: "Gastos",
                data: !hideFinances ? chartData.map(item => item.spendValue) : 0,
                backgroundColor: 'rgb(237, 76, 92, 0.6)',
            }
        ]
    };



    return (
        <div className='FinancialCardSummaryShadow sizeEstatisticsSummary'>
            <div className='FinancialCardSummaryContainer formatEstatisticsSummary'>
                {(incomeData && spendData) && (
                    <>
                        <header>
                            <p>Ver estatísticas</p>
                        </header>
                        <div>
                            {hasData && <Bar data={data} options={chartOptions} plugins={[ChartDataLabels]} />}
                        </div>
                    </>
                )}
            </div>
        </div>
    );

}

export default EstatisticsSummaryCard;