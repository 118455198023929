import { useEffect, useState } from 'react';
import './ChangeMonthComponent.css'
import { useFinancialContext } from '../../../../../../FinancialContext';
import useWindowWidth from '../../../../../../useWindowWidth';

function ChangeMonthComponent({ userClickedOutOfWindow }) {
    const { changeYear, changeMonth, monthName, monthNumber, yearSelected } = useFinancialContext();
    const isMobile = useWindowWidth();

    const [changeMonthActive, setChangeMonthActive] = useState(false);
    const [previousMonth, setPreviousMonth] = useState();
    const [nextMonth, setNextMonth] = useState();

    const months = ["Janeiro-1", "Feveveiro-2", "Março-3", "Abril-4", "Maio-5", "Junho-6", "Julho-7", "Agosto-8", "Setembro-9", "Outubro-10", "Novembro-11", "Dezembro-12"];

    const stopPropagation = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }
    const handleClickChangeMonth = (e) => {
        stopPropagation(e)
        setChangeMonthActive(!changeMonthActive);
    }

    const updateNextPreviousMonth = () => {

        const selected = months.findIndex(mes => monthNumber === mes.split("-")[1])
        if (selected === 11) {
            setNextMonth("Janeiro")
        } else {
            const index = selected + 1;
            setNextMonth(months[index].split("-")[0])
        }

        if (selected === 0) {
            setPreviousMonth("Dezembro")
        } else {
            const index = selected - 1;
            setPreviousMonth(months[index].split("-")[0])
        }
    }

    const handleSubMonth = () => {
        if (monthNumber === '1') {
            changeMonth('12');
            changeYear(yearSelected - 1);
        } else {
            changeMonth((parseInt(monthNumber) - 1).toString());
        }
        updateNextPreviousMonth();
    }

    const handleAddMonth = () => {
        if (monthNumber === '12') {
            changeMonth('1');
            changeYear(yearSelected + 1);
        } else {

            changeMonth((parseInt(monthNumber) + 1).toString());
        }
        updateNextPreviousMonth();
    }

    useEffect(() => {
        if (userClickedOutOfWindow) {
            setChangeMonthActive(false);
        }
    }, [userClickedOutOfWindow])

    useEffect(() => {
        updateNextPreviousMonth();
    }, [yearSelected, monthNumber])

    const openWindowChangeMonth = (
        <div className='OpenWindowChangeMonth' onClick={(e) => e.stopPropagation()}>
            <img onClick={handleSubMonth} src='Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/ArrowHeaderCalendar.svg' />
            <p onClick={handleSubMonth}>{previousMonth}</p>
            <p onClick={() => setChangeMonthActive(false)}>{monthName}</p>
            <p onClick={handleAddMonth}>{nextMonth}</p>
            <img onClick={handleAddMonth} src='Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/ArrowHeaderCalendar.svg' />
        </div>
    )
    const changeMonthDesktop = (
        <div className='selectMonthContainer'
            style={{
                width: !changeMonthActive ? '10vw' : '18vw'
            }}
        >
            {!changeMonthActive ? (
                <button onClick={handleClickChangeMonth}>
                    <div>
                        <p>{monthName}</p>
                    </div>
                    <div>
                        <img src='Images_Dashboard_Summary/DefaultIcons/CalendarChangeMonthButton.svg' />
                    </div>
                </button>
            ) : openWindowChangeMonth}
        </div>
    )

    const changeMonthSmartphone = (
        <div className='changeMonthContainerSmartphone'
            style={{
                width: !changeMonthActive ? '35%' : '100%'
            }}
        >
            {!changeMonthActive ? (

                <button onClick={handleClickChangeMonth}>
                    <p>{monthName}
                        <p>{yearSelected}</p>
                    </p>
                    <img src='Images_Dashboard_Summary/DefaultIcons/SmartphoneIcons/ArrowHeaderCalendar.svg' />
                </button>
            ) : openWindowChangeMonth}
        </div>
    )


    return (
        <>
            {isMobile ? changeMonthSmartphone : changeMonthDesktop}
        </>
    )
}
export default ChangeMonthComponent;