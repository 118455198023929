import "./Section5FormAboutUs.css"

const Section5FormAboutUs = ({ nameInputted, setName, emailInputted, setEmail, messageInputted, setMessage }) => {

    const handleSubmitForm = (e) => {
        e.preventDefault();
    }

    return (
        <section className="Section5FormAboutUs">
            <form onSubmit={handleSubmitForm}>
                <input
                    type="text"
                    placeholder="Nome"
                    //value={nameInputted}
                    //onChange={(e) => setName(e.target.value)}
                ></input>

                <input
                    type="text"
                    placeholder="Email"
                    //value={emailInputted}
                    //onChange={(e) => setEmail(e.target.value)}
                ></input>

                <textarea
                    type="text"
                    placeholder="Mensagem"
                    //value={messageInputted}
                    //onChange={(e) => setMessage(e.target.value)}
                ></textarea>

                <div >
                    <button>
                        Enviar mensagem
                    </button>
                </div>
            </form>
        </section>

    )
}

export default Section5FormAboutUs;