import './CreditCardCard.css'
import { useEffect, useState } from 'react';
import { useFinancialContext } from '../../../../../../../FinancialContext';
import axios from 'axios';
import Dropbox from '../../FinanceOpenCard/Dropbox';
import { getAllBanks, getBankLogo, checkBankExist } from '../../../../../../../staticData';

function CreditCardCard({ hasData, openEditView, renderNoData, setError_message, setOpenEditView, creditCardView, viewClicked }) {
    const { axiosURL, hideFinances, monthNumber, creditCardData, updateDeleteCreateCreditCard, spendData,renderFinanceHandler, yearSelected
    } = useFinancialContext();

    const banks = getAllBanks();

    const [noCreditCard, setNoCreditCard] = useState(false);
    const [billInMonth, setNoBillInMonth] = useState(false);

    const [creditCardUser, setCreditCardUser] = useState(null);
    const [inEditCreditCard, setInEditCreditCard] = useState(false);
    const [creditCardInEdit, setCreditCardInEdit] = useState(null);

    const [creatingNewCreditCard, setCreatingNewCreditCard] = useState(true);
    const [showFooterNewCreditCard, setShowFooterNewCreditCard] = useState(false);

    const [bankSelected, setBankSelected] = useState();

    const [newCreditCardInput, setNewCreditCardInput] = useState({
        nome_do_cartao: null,
        banco_cartao: null,
        dia_do_fechamento_da_fatura: null,
        dia_do_vencimento_da_fatura: null,
        cartao_personalizado: false
    });
    const handleCancelEditCreditCard = () => {
        setInEditCreditCard(false);
        setCreditCardInEdit(null)
        setCreatingNewCreditCard(false);
    }
    const handleClickNewCreditCard = () => {
        setCreatingNewCreditCard(true);
        setTimeout(() => {
            setShowFooterNewCreditCard(true);
        }, 100)
    }
    const handleCancelCreateNewCreditCard = () => {
        setBankSelected(null);
        setCreatingNewCreditCard(false);
        setShowFooterNewCreditCard(false);
        setNewCreditCardInput({
            nome_do_cartao: null,
            banco_cartao: null,
            dia_do_fechamento_da_fatura: null,
            dia_do_vencimento_da_fatura: null,
            cartao_personalizado: false
        })
    }
    const handleClickSelectBank = (bank) => {
        setBankSelected(bank);
        handleInputChange(bank.nameInput, 'nome_do_cartao')
        handleInputChange(bank.findIndex, 'banco_cartao');
    }
    const handleInputNumber = (value, field) => {
        const filteredValue = value.replace(/\D/g, '');
        handleInputChange(filteredValue, field);
    }
    const handleInputChange = (value, field) => {
        setNewCreditCardInput((prevData) => ({
            ...prevData,
            [field]: value
        }));
    }


    const renderBankLogo = (banco_cartao) => {
        const logo = getBankLogo(banco_cartao);
        return logo && <img src={logo} alt="Bank logo" />;
    };

    const handleCreateNewCreditCard = () => {
        if (
            (newCreditCardInput.banco_cartao && !checkBankExist(newCreditCardInput.banco_cartao))
            || !newCreditCardInput.dia_do_fechamento_da_fatura
            || !newCreditCardInput.dia_do_vencimento_da_fatura
            || !newCreditCardInput.banco_cartao
        ) {
            setError_message("Preencha todos os campos.");
            return;
        } if (newCreditCardInput.dia_do_fechamento_da_fatura < 1
            || newCreditCardInput.dia_do_fechamento_da_fatura > 30
            || newCreditCardInput.dia_do_vencimento_da_fatura < 1
            || newCreditCardInput.dia_do_vencimento_da_fatura > 30
        ) {
            setError_message("Número do dia inválido.");
            return;
        }
        if (!newCreditCardInput.banco_cartao && !newCreditCardInput.nome_do_cartao) {
            setError_message("Cartões sem banco precisam de nome!");
            return;
        }
        fetchCreateNewCreditCard();
    }

    const fetchCreateNewCreditCard = async () => {

        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.post(`${axiosURL}/creditCard`, newCreditCardInput, config);

            if (response.status === 201) {
                updateDeleteCreateCreditCard("create", response.data);
                handleCancelCreateNewCreditCard(); 
            }
        } catch (error) {
            console.log(error);
        }


    }


    const newCreditCard = () => {

        return (
            <section className='createNewCreditCardSection'
                style={{
                    height: creatingNewCreditCard ? '65%' : '14.5%'
                }}
            >
                {creatingNewCreditCard ?
                    (
                        <div style={{ borderTop: '#D1D1D1 solid 0.1vw' }}>
                            <div>
                                <div>
                                    <Dropbox
                                        data={banks}
                                        handleClick={handleClickSelectBank}
                                        selectedValue={bankSelected}
                                        closeFilterWindows={viewClicked}
                                        setCloseFilterWindows={() => { }}
                                        textAlign={'left'}
                                    />
                                </div>

                                <div className='inputLabelCreditCard'>
                                    <label>Nome do cartão</label>
                                    <input type='text' maxLength={50}
                                        onChange={(e) => handleInputChange(e.target.value, 'nome_do_cartao')}
                                        value={newCreditCardInput.nome_do_cartao}
                                        placeholder={'Nome'}
                                    />
                                </div>
                            </div>
                            <div>
                                <div className='inputLabelCreditCard'>
                                    <label>Dia fechamento fatura</label>
                                    <input type='text' maxLength={2} min="1" max="31"
                                        onChange={(e) => handleInputNumber(e.target.value, 'dia_do_fechamento_da_fatura')}
                                        value={newCreditCardInput.dia_do_fechamento_da_fatura}
                                        placeholder={'Dia'}
                                    />
                                </div>
                                <div className='inputLabelCreditCard'>
                                    <label>Dia vencimento fatura</label>
                                    <input type='text' maxLength={2} min="1" max="31"
                                        onChange={(e) => handleInputNumber(e.target.value, 'dia_do_vencimento_da_fatura')}
                                        value={newCreditCardInput.dia_do_vencimento_da_fatura}
                                        placeholder={'Dia'}
                                    />
                                </div>
                            </div>
                            {showFooterNewCreditCard && (
                                <footer>
                                    <p onClick={handleCancelCreateNewCreditCard}>Cancelar</p>
                                    <button className='buttonNewCreditCard' onClick={handleCreateNewCreditCard}>Criar</button>
                                </footer>
                            )}
                        </div>
                    ) :
                    <button onClick={handleClickNewCreditCard} className='buttonNewCreditCard'>Novo cartão</button>

                }
            </section>
        )
    }

    const renderEditCreditCardData = () => {
        const handleEditCreditCard = (creditCard) => {
            const creditCardInEditAux = {
                id: creditCard.id,
                nome_original: creditCard.nome_do_cartao,
                nome_do_cartao: creditCard.nome_do_cartao,
                dia_do_fechamento_da_fatura_original: creditCard.dia_do_fechamento_da_fatura,
                dia_do_vencimento_da_fatura_original: creditCard.dia_do_vencimento_da_fatura,
                cor_do_cartao_original: creditCard.cor_do_cartao,
                dia_do_fechamento_da_fatura: creditCard.dia_do_fechamento_da_fatura,
                dia_do_vencimento_da_fatura: creditCard.dia_do_vencimento_da_fatura,
                cor_do_cartao: creditCard.cor_do_cartao,
                banco_cartao: creditCard.banco_cartao
            }
            setCreditCardInEdit(creditCardInEditAux);
            setInEditCreditCard(true);
        }

        const handleCreditCardInput = (value, input) => {
            let valueFiltered = value;

            if (input !== 'nome_do_cartao' && input !== 'nome_original') {
                valueFiltered = value.replace(/\D/g, '');
            }

            setCreditCardInEdit((prevInputs) => ({
                ...prevInputs,
                [input]: valueFiltered
            }));
        }

        const handleUpdateCreditCard = () => {

            if (!creditCardInEdit.nome_do_cartao
                || !creditCardInEdit.dia_do_fechamento_da_fatura
                || !creditCardInEdit.dia_do_vencimento_da_fatura
            ) {
                setError_message("Os valores não podem ser nulos!");
                return;
            }
            if (creditCardInEdit.nome_do_cartao === creditCardInEdit.nome_original
                && creditCardInEdit.dia_do_fechamento_da_fatura === creditCardInEdit.dia_do_fechamento_da_fatura_original
                && creditCardInEdit.dia_do_vencimento_da_fatura === creditCardInEdit.dia_do_vencimento_da_fatura_original
                && creditCardInEdit.cor_do_cartao === creditCardInEdit.cor_do_cartao_original
            ) {
                setOpenEditView(false);
                return;
            }

            const creditCard = {
                id: creditCardInEdit.id,
                nome_do_cartao: creditCardInEdit.nome_do_cartao,
                dia_do_fechamento_da_fatura: creditCardInEdit.dia_do_fechamento_da_fatura,
                dia_do_vencimento_da_fatura: creditCardInEdit.dia_do_vencimento_da_fatura,
                cor_do_cartao: creditCardInEdit.cor_do_cartao
            }

            fetchUpdateCreditCard(creditCard);
        }


        const renderLines = (
            <div className='CreditCardTableEdit'>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <td className='borderBottomTitleTableCategories' colSpan="4" />
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(creditCardData.content).map((creditCard) => (
                            <tr key={creditCard.id}>
                                <td>{creditCard.nome_do_cartao}</td>
                                <td><p onClick={() => handleEditCreditCard(creditCard)}>Editar</p></td>
                                <td><p onClick={() => fetchDeleteCreditCard(creditCard)}>Excluir</p></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {newCreditCard()}
            </div>
        )


        const renderEditCreditCard = () => (
            <div className='CreditCardTableData'>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nome</th>
                            <th>Dia fechamento <br /> da fatura</th>
                            <th>Dia vencimento <br /> da fatura</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='borderBottomTitleTableCategories' colSpan="4" />
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {renderBankLogo(creditCardInEdit.banco_cartao)}
                            </td>
                            <td>
                                <input value={creditCardInEdit.nome_do_cartao} maxLength={80} onChange={(e) => handleCreditCardInput(e.target.value, 'nome_do_cartao')} />
                            </td>
                            <td>
                                <input value={creditCardInEdit.dia_do_fechamento_da_fatura} maxLength={2} onChange={(e) => handleCreditCardInput(e.target.value, 'dia_do_fechamento_da_fatura')} />
                            </td>
                            <td>
                                <input value={creditCardInEdit.dia_do_vencimento_da_fatura} maxLength={2} onChange={(e) => handleCreditCardInput(e.target.value, 'dia_do_vencimento_da_fatura')} />
                            </td>
                        </tr>
                        <tr>
                            <td />
                            <td colSpan={3} id='formatConfirmCancelEditCreditCard'>
                                <p onClick={handleUpdateCreditCard}>
                                    Confirmar
                                </p>
                                <p onClick={handleCancelEditCreditCard}>
                                    Cancelar
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )

        return (
            <>
                {!inEditCreditCard ? renderLines : renderEditCreditCard()}
            </>
        )
    }

    const renderCreditCardData = () => {

        return (
            <div className='CreditCardTableData'>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Nome</th>
                            <th>Fechamento</th>
                            <th>Vencimento</th>
                            <th>Fatura</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td className='borderBottomTitleTableCategories' colSpan="4" />
                        </tr>
                    </thead>
                    <tbody>
                        {creditCardUser && Object.values(creditCardUser).map((creditCard) => (
                            <tr key={creditCard.id}>
                                <td>{renderBankLogo(creditCard.banco_cartao)}</td>
                                <td>{creditCard.nome_do_cartao}</td>
                                <td>{creditCard.dia_do_fechamento_da_fatura}/{monthNumber}</td>
                                <td>{creditCard.dia_do_vencimento_da_fatura}/{monthNumber}</td>
                                <td>R${hideFinances ? "●●" : creditCard.valor.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }



    const fetchDeleteCreditCard = async (creditCard) => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.delete(`${axiosURL}/creditCard/${creditCard.id}`, config);
            if (response.status === 200) {
                updateDeleteCreateCreditCard("delete", creditCard);
                handleCancelEditCreditCard();
            }
        } catch (error) {
            console.log(error);
        }
    }
    const fetchUpdateCreditCard = async (creditCard) => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const response = await axios.put(`${axiosURL}/creditCard`, creditCard, config);

            if (response.status === 200) {
                updateDeleteCreateCreditCard("update", response.data);
                handleCancelEditCreditCard();
            }
        } catch (error) {
            console.log(error);
        }
    }


    const calculateCreditCard = (data) => {
        if (creditCardData && creditCardData.content.length === 0) setNoCreditCard(true);
        else setNoCreditCard(false)
        if (data.content.length > 0) {
            const creditCardsIdsWithIncomeSpend = data.content.map(element => element.creditCard?.id || null);
            const creditCardsThatHaveIncomeSpend = creditCardData.content.filter(creditCard => creditCardsIdsWithIncomeSpend.includes(creditCard.id));

            let creditCardArray = creditCardsThatHaveIncomeSpend.map((card) => ({
                id: card.id,
                banco_cartao: card.banco_cartao,
                cartao_personalizado: card.cartao_personalizado,
                cor_do_cartao: card.cor_do_cartao,
                dia_do_fechamento_da_fatura: card.dia_do_fechamento_da_fatura,
                dia_do_vencimento_da_fatura: card.dia_do_vencimento_da_fatura,
                nome_do_cartao: card.nome_do_cartao,
                valor: data.content
                    .filter(incomeSpend => incomeSpend.creditCard?.id === card.id)
                    .reduce((acc, current) => acc + current.valor, 0)
            }))

            creditCardArray.sort((a, b) => b.valor - a.valor);
            if (creditCardArray.length === 0) setNoBillInMonth(true);
            else setNoBillInMonth(false);

            setCreditCardUser(creditCardArray);

        }

    }

    useEffect(() => {
        const data = {
            content: spendData.content.map(item => {
                const finance = renderFinanceHandler(item);
                return finance ? finance : null;
            }).filter(item => item !== null)
        }
        calculateCreditCard(data);
    }, [spendData, yearSelected, creditCardData, monthNumber])

    useEffect(() => {
        setCreatingNewCreditCard(false);
        setBankSelected(null);
        setCreditCardInEdit(null);
        setInEditCreditCard(false);
        setShowFooterNewCreditCard(false);
    }, [openEditView])


    return (
        <>
            {(creditCardView && noCreditCard && hasData) && (
                <div style={{marginLeft: '5%'}}>
                    {newCreditCard()}
                </div>
            )}{/*Usuário sem cartão nenhum*/}
            {(creditCardView && !noCreditCard && hasData && !openEditView && billInMonth) && renderNoData("Cartões sem valores na fatura.")}{/*Usuário com cartão sem fatura*/}

            {(creditCardView && hasData && !openEditView && !noCreditCard && !billInMonth) && renderCreditCardData()}
            {(creditCardView && hasData && openEditView && !noCreditCard) && renderEditCreditCardData()}
        </>
    )
}

export default CreditCardCard;