import "./Section1.css"

const Section1 = () => {
    return (
        <section className="Section1AboutUs">
            <img src='Images_AboutUs/section1img.svg'/>
            <div className="RightSideTextSection1">
                <h1>Aqui você pode conhecer a gente mais de perto! </h1>
                <h2>O melhor APP de controle financeiro do Brasil, tornamos suas finanças simples com Sunna.</h2>
            </div>
        </section>
    )
}

export default Section1;