import './UserForgotPasswordComponents.css'
import InputLoginSingIn from '../../../Page 3 - NewUser/InputLoginSingIn';
import CheckCodeForgotPassword from '../CheckCodeForgotPassword'
import React, { useState } from 'react';
import Cookies from "universal-cookie";
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { useFinancialContext } from '../../../../FinancialContext';

function UserForgotPasswordComponents({ selectedInput, handleInputClicked }) {
    const { axiosURL } = useFinancialContext();
    const cookies = new Cookies();
    const navigate = useNavigate();

    const [inputClicked, setInputClicked] = useState(null);
    const [messageErrorForgotPassword, setMessageErrorForgotPassword] = useState('');
    const [messageForgotPassword, setMessageForgotPassword] = useState('');

    const [email, setEmail] = useState('');
    const [cpf, setCpf] = useState('');

    const [confirmCode, setConfirmCode] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    //Função que atualiza o input do CPF e garante que terá pontos e traços
    const handleCPFInput = (value) => {
        const rawCpf = value.replace(/\D/g, '');
        let formattedCpf = '';

        for (let i = 0; i < rawCpf.length; i++) {
            if (i === 3 || i === 6) {
                formattedCpf += '.';
            } else if (i === 9) {
                formattedCpf += '-';
            }
            formattedCpf += rawCpf[i];
        }
        setCpf(formattedCpf);
    }


    //Função que verifica a autenticidade do CPF digitado
    const handleCpfAuthenticity = () => {
        const rawCpf = cpf.replace(/[.-]/g, '');

        let sum = 0, cont = 10;
        let firstDigit;
        let secondDigit;

        for (let i = 0; i < 9; i++) {
            sum += cont * parseInt(rawCpf[i]);
            cont--;
        }
        let rest = sum % 11;
        if (rest < 2) {
            firstDigit = 0
        } else {
            firstDigit = 11 - rest
        }

        cont = 10; sum = 0; rest = 0;

        for (let i = 1; i < 10; i++) {
            sum += cont * parseInt(rawCpf[i])
            cont--
        }

        rest = sum % 11;
        if (rest < 2) {
            secondDigit = 0;
        } else {
            secondDigit = 11 - rest;
        }

        if (parseInt(rawCpf[9], 10) === firstDigit && parseInt(rawCpf[10], 10) === secondDigit) {
            setMessageErrorForgotPassword('');
            return true
        } else {
            setMessageErrorForgotPassword('O CPF não está válido')
            return false
        }

    }

    //Função de requisição. Solicita ao backend a geração do código aleatório, disparo para o e-mail do usuário, e caso seja bem sucessido, coloca nos coockies o emall digitado e segue para a etapa de confirmação do código
    //Se não, informa um erro.
    const addPost = (e) => {
        e.preventDefault();

        if (cpf === '' || email === '') {
            setMessageErrorForgotPassword("É obrigatório preencher todos os campos para prosseguir.");
            return;
        }

        if (!handleCpfAuthenticity()) return;

        setMessageErrorForgotPassword('')
        setMessageForgotPassword("O e-mail está sendo enviado, isso pode demorar alguns segundos.");
        setIsButtonDisabled(true);

        const values = {
            cpf: cpf,
            email: email
        }

        axios.post(`${axiosURL}/forgot-password/send-recovery-code`, values)
            .then(response => {
                if (response.status === 200) {
                    cookies.set("email_recovery_password_user", encodeURIComponent(email));
                    setConfirmCode(true);
                }
            }).catch(error => {
                if (error.response && error.response.status === 404) {
                    setMessageErrorForgotPassword("Os dados informados não coincidem com nenhum usuário.");
                    setMessageForgotPassword('');
                    setIsButtonDisabled(false);
                } else {
                    setMessageErrorForgotPassword("Ocorreu um erro, mas já estamos trabalhando para resolver!");
                    setMessageForgotPassword('');
                    setIsButtonDisabled(false);
                }
            })
    };


    if (!confirmCode) {
        return (
            <form onSubmit={addPost} className='FormsConfirmCode' onKeyDown={(e) => { if (e.key === 'Enter') addPost(e) }}>
                <header>
                    <h1>Recupere a sua conta</h1>
                    <p>Esqueceu a senha? Siga os passos com bastante <span>atenção!</span></p>
                </header>
                <div>
                    <InputLoginSingIn
                        inputType={'email'}
                        textPlaceholder={"Digite e-mail da sua conta"}
                        moveTitle={"E-mail"}
                        selectedInput={selectedInput}
                        clickedInput={handleInputClicked}
                        setInputClicked={setInputClicked}
                        inputClicked={inputClicked}
                        valueInputed={email}
                        valueSetter={setEmail}
                        inputSize={70}
                    />
                    <InputLoginSingIn
                        inputType={'text'}
                        textPlaceholder={"Digite o CPF da sua conta"}
                        moveTitle={"CPF"}
                        selectedInput={selectedInput}
                        clickedInput={handleInputClicked}
                        setInputClicked={setInputClicked}
                        inputClicked={inputClicked}
                        valueInputed={cpf}
                        valueSetter={handleCPFInput}
                        inputSize={14}
                    />
                </div>
                <footer>
                    <Link onClick={() => navigate('/login')}>Voltar</Link>
                    <button disabled={isButtonDisabled}>Confirmar</button>
                </footer>
                <p id='MessageForgotPassword'>{messageForgotPassword}</p>
                <p id='ErrorMessageForgotPassword'>{messageErrorForgotPassword}</p>
            </form>
        )
    }

    if (confirmCode) {
        return (
            <CheckCodeForgotPassword
                selectedInput={selectedInput}
                handleInputClicked={handleInputClicked}
            />
        )
    }

}

export default UserForgotPasswordComponents;