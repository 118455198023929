import './ChangePassword.css'
import axios from "axios";
import InputLoginSingIn from '../../../Page 3 - NewUser/InputLoginSingIn';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useFinancialContext } from '../../../../FinancialContext';

function ChangePassword({ selectedInput, handleInputClicked }) {
    const { axiosURL } = useFinancialContext();

    const cookies = new Cookies();
    const navigate = useNavigate();
    const [inputClicked, setInputClicked] = useState(null);

    const [storedEmail, setStoredEmail] = useState('');
    const [storedCodeRecoveryPassword, setStoredCodeRecoveryPassword] = useState('');

    const [errorMessage, setErrorMessage] = useState('');
    const [messageChangePassword, setMessageChangePassword] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    //Função que verifica se a senha possui letras, números e caracteres especiais e que vê se a confirmPassword bate com a password
    const handleVerifyPassword = () => { 

        const hasLetter = /[a-zA-Z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialCaracter = /[!@#$%^&*(),.?":{}|<>]/.test(password);

        if(!hasLetter || !hasNumber || !hasSpecialCaracter || password.length < 8) {
            setErrorMessage("A senha precisa ter pelo menos uma letra, um número, um caractere especial e pelo menos 8 caracteres.")
            return false;
        }

        if(password !== confirmPassword){
            setErrorMessage("As senhas não coincidem!")
            return false;
        }

        return true;
        
    }

    //Função de requisição, que envia a nova senha do usuário, o código e o e-mail do usuário para a realização ser feita. Caso seja bem sucedida todos os coockies são removidos e o usuário é redirecionado para a página de login
    //Acontece também antes de tudo uma verificação de se as senhas batem.
    const addPost = (e) => {
        e.preventDefault();
        if(!handleVerifyPassword()) return;

        const data = {
            password: password,
            userEmail: storedEmail,
            codeRecoveryPassword: storedCodeRecoveryPassword
        }

        axios.post(`${axiosURL}/forgot-password/change-password-by-recovery-code`, data)
            .then(response => {
                if (response.status === 200) {
                    cookies.remove('code_recovery_password_user');
                    cookies.remove('email_recovery_password_user');
                    setErrorMessage('');
                    setMessageChangePassword("Senha alterada com sucesso!");
                    setTimeout(() => {
                        navigate('/login');
                    }, 2500);
                }
            })
            .catch(error => {
                setErrorMessage("Ocorreu um erro ao alterar a senha, mas já estamos trabalhando para resolver!")
            });
    }

    //UseEffect que pega dos coockies do usuário o e-mail dele e o código de verificação
    useEffect(() => {
        setStoredEmail(decodeURIComponent(cookies.get("email_recovery_password_user")));
        setStoredCodeRecoveryPassword(cookies.get("code_recovery_password_user"));
    }, []);


    return (
        <form className='FormsChangePassword' onSubmit={addPost} onKeyDown={(e) => { if (e.key === 'Enter') addPost(e) }}>
            <header>
                <h1>Crie uma nova senha</h1>
                <p>Deu tudo certo! </p>
                <p>Agora só informa para a gente a nova senha que deseja ter na sua conta, para voltar a acessá-la normalmente.
                    Lembre-se que a senha precisa ter no mínimo <span>8 caracteres, com letras, números e caractere especial.</span></p>
            </header>
            <section>
                <InputLoginSingIn
                    inputType={'password'}
                    textPlaceholder={"Informe a nova senha"}
                    moveTitle={"Senha"}
                    selectedInput={selectedInput}
                    clickedInput={handleInputClicked}
                    setInputClicked={setInputClicked}
                    inputClicked={inputClicked}
                    valueInputed={password}
                    valueSetter={setPassword}
                    inputSize={50}
                />
                <InputLoginSingIn
                    inputType={'password'}
                    textPlaceholder={"Confirme a senha digitada acima"}
                    moveTitle={"Confirmação de senha"}
                    selectedInput={selectedInput}
                    clickedInput={handleInputClicked}
                    setInputClicked={setInputClicked}
                    inputClicked={inputClicked}
                    valueInputed={confirmPassword}
                    valueSetter={setConfirmPassword}
                    inputSize={50}
                />
            </section>
            <footer>
                <Link to={"/login"}>Voltar</Link>
                <button>Confirmar</button>
            </footer>
            <p id='errorMessageChangePassword'>{errorMessage}</p>
            <p id='messageChangePassword'>{messageChangePassword}</p>
        </form>
    )
}

export default ChangePassword;