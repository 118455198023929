import './EstatisticasView.css'
import HistoricoReceitasGastos from './EstatísticasCards/HistoricoReceitasGastos';
import PorcentagemCategoriasHistorico from './EstatísticasCards/PorcentagemCategoriasHistorico'
import { useFinancialContext } from '../../../../FinancialContext';


function EstatísticasView() {
    const { incomeData, spendData } = useFinancialContext();

    const sectionOne = () => {

        return (
            <section>
                <p>Histórico</p>
                <div>
                    <HistoricoReceitasGastos/>
                </div>
            </section>
        )
    }

    const sectionTwo = () => {

        return (
            <section>
                <p>Participaçãos mensal por categoria</p>
                <div>
                    <div>
                        <PorcentagemCategoriasHistorico
                            incomeSpendData={incomeData}
                            type={"income"}
                        />
                    </div>
                    <div>
                        <PorcentagemCategoriasHistorico
                            incomeSpendData={spendData}
                            type={"spend"}
                        />
                    </div>
                </div>
            </section>
        )
    }

    return (
        <div className='estatisticsSectionContainer'>
            {sectionOne()}
            {sectionTwo()}
        </div>
    )
}

export default EstatísticasView;