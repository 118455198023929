import React from 'react';
import { Bar, Line } from 'react-chartjs-2';
import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import "./HistoricoReceitasGastos.css";
import { useState, useEffect } from 'react';
import { subMonths, format, addMonths } from 'date-fns';
import { _toLeftRightCenter } from 'chart.js/helpers';
import { useFinancialContext } from '../../../../../../FinancialContext';
import useWindowWidth from '../../../../../../useWindowWidth';

function HistoricoReceitasGastos() {
    const [chartData, setChartData] = useState([]);
    const isMobile = useWindowWidth();

    const { hideFinances, yearSelected, monthNumber, incomeData, spendData, renderFinanceHandler } = useFinancialContext();

    // Exemplo de dados fornecidos


    //Principal função para calculo do valor total histórico de receitas e gastos por mês - L12M
    useEffect(() => {
        if (incomeData && spendData) {
            const lastMonth = new Date(yearSelected, monthNumber - 1, '01') //Puxa o mês atual que o usuário está vendo
            const startMonth = subMonths(lastMonth, 12) //Mês atual menos 12 meses

            let incomesByMonth = [{}]
            let spendByMonth = [{}]

            for (let i = 0; i <= 12; i++) { //For para comparar e pegar as informações das finanças separadas por mês
                const dateToCompare = addMonths(startMonth, i);

                //Pega as finanças e agrupa elas em um array, onde a chave é o mês e o valor o valor total da finança naquele mês
                incomesByMonth = {
                    ...incomesByMonth,
                    [dateToCompare]: incomeData.content.map((income) => {
                        const finance = renderFinanceHandler(income, dateToCompare);
                        if (finance) return finance;
                    })
                        .filter((income) => income !== null && income !== undefined)
                        .reduce((acc, income) => { return acc + income.valor }, 0)
                }

                spendByMonth = {
                    ...spendByMonth,
                    [dateToCompare]: spendData.content.map((spend) => {
                        const finance = renderFinanceHandler(spend, dateToCompare);
                        if (finance) return finance;
                    })
                        .filter((spend) => spend !== null && spend !== undefined)
                        .reduce((acc, spend) => { return acc + spend.valor }, 0)
                }


            }
            //Ordenação, formatação para MM/yyyy e exclusão de itens undefined
            incomesByMonth = Object.entries(incomesByMonth);
            incomesByMonth = incomesByMonth.map((item) => {
                if (item[0] !== "0") {
                    return {
                        date: format(new Date(item[0]), 'MM/yyyy'),
                        valueIncome: item[1]
                    };
                }
            }).filter((item) => item !== undefined);

            spendByMonth = Object.entries(spendByMonth);
            spendByMonth = spendByMonth.map((item) => {
                if (item[0] !== "0") {
                    return {
                        date: format(new Date(item[0]), 'MM/yyyy'),
                        valueSpend: item[1]
                    };
                }
            }).filter((item) => item !== undefined);

            //Agrupando as informações de gastos e receitas e excluindo os meses sem informação

            const finalChartData = incomesByMonth.map((income) => {
                const spend = spendByMonth.find((spend) => spend.date === income.date);
                return {
                    date: income.date,
                    incomeValue: income.valueIncome,
                    spendValue: spend ? spend.valueSpend : 0
                };
            });

            setChartData(finalChartData);
        }
    }, [monthNumber, yearSelected, incomeData, spendData])

    // Preparar os dados para o gráfico
    const chartDesktop = {
        labels: Object.values(chartData).map((item) => item.date),
        datasets: [
            {
                label: 'Receitas',
                data: Object.values(chartData).map((item) => !hideFinances ? item.incomeValue : 0),
                fill: false,
                backgroundColor: 'rgb(72, 153, 70, 0.6)',
                borderColor: 'rgb(72, 153, 70, 0.6)',
                borderWidth: 2,
                pointBackgroundColor: '#489946',
                pointRadius: 3,
                datalabels: {
                    anchor: 'end',
                    align: 'right',
                    offset: 3,
                    clip: false,
                    formatter: (value) => "R$ " + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            },
            {
                label: 'Gastos',
                data: Object.values(chartData).map((item) => !hideFinances ? item.spendValue : 0),
                fill: false,
                backgroundColor: 'rgb(237, 76, 92, 0.6)',
                borderColor: 'rgb(237, 76, 92, 0.6)',
                borderWidth: 2,
                pointBackgroundColor: '#ED4C5C',
                pointRadius: 3,
                datalabels: {
                    anchor: 'start',
                    align: 'left',
                    offset: 3,
                    formatter: (value) => "R$ " + value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                }
            }
        ]
    };
    const chartOptionsDesktop = {
        plugins: {
            legend: {
                labels: {
                    font: {
                        family: 'Jost',
                        size: 15,
                    }
                },
                onHover: function (event) {
                    event.native.target.style.cursor = 'pointer';
                },
                onLeave: function (event) {
                    event.native.target.style.cursor = 'default';
                }
            },
            datalabels: {
                formatter: Math.round,
                clip: false,
                font: {
                    family: 'Jost',
                    size: 13,
                    color: '#333333'
                }
            }
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Mês',
                    font: {
                        family: 'Jost',
                        size: 16,
                    }
                },
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: 'Jost', // Configuração da fonte para os ticks do eixo y
                        size: 14, // Tamanho da fonte dos ticks
                    }
                }
            },
            y: {
                title: {
                    display: true,
                    text: 'Valor',
                    font: {
                        family: 'Jost',
                        size: 16,
                    }
                },
                ticks: {
                    font: {
                        family: 'Jost', // Configuração da fonte para os ticks do eixo y
                        size: 14, // Tamanho da fonte dos ticks
                    }
                }
            }
        },
        maintainAspectRatio: false,
        layout: {
            padding: {
                right: 90,
                left: 30
            }
        }
    };

    const chartOptionsSmartphone = {
        responsive: true,
        maintainAspectRatio: false,

        plugins: {
            legend: {
                position: 'top',

                labels: {
                    font: {
                        family: 'Jost',
                        size: 13,
                    },
                    padding: 5
                }
            },
            datalabels: {
                color: '#000000',
                anchor: 'end',
                align: 'center',
                font: {
                    size: 10,
                    family: 'Jost'
                },
                formatter: (value) => {
                    return "R$" + Math.round(value); // Formata os números como string localizada
                }
            }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 10,
                    }
                }
            },
            y: {
                ticks: {
                    font: {
                        family: 'Jost',
                        size: 10,
                    }
                }
            }
        },
        layout: {
            padding: {
                right: 15,
                left: 10,
            }
        },
    };
    const chartSmartphone = {
        labels: chartData.slice(-5).map(item => item.date),
        datasets: [
            {
                label: "Receitas",
                data: !hideFinances ? chartData.slice(-5).map(item => item.incomeValue) : 0,
                backgroundColor: 'rgb(72, 153, 70, 0.6)',
            },
            {
                label: "Gastos",
                data: !hideFinances ? chartData.slice(-5).map(item => item.spendValue) : 0,
                backgroundColor: 'rgb(237, 76, 92, 0.6)',
            }
        ]
    };

    return (
        <div className='FinancialCardSummaryShadow'>
            <div className='FinancialCardSummaryContainer HistoricoReceitasGastosContainer'>
                {(incomeData && spendData) && (
                    <>
                        <header>
                            <p>Histórico mensal das suas finanças</p>
                        </header>
                        {isMobile ?
                            <div className="chart-container-smartphone">
                                <Bar data={chartSmartphone} options={chartOptionsSmartphone} plugins={[ChartDataLabels]} />
                            </div>
                            :
                            <div className="chart-container-desktop">
                                <Line data={chartDesktop} options={chartOptionsDesktop} plugins={[ChartDataLabels]} />
                            </div>
                        }
                    </>
                )}
            </div>
        </div>
    );
}

export default HistoricoReceitasGastos;
