import "./mainCreateNewUser.css"
import FormsCreateNewUser from "./FormsCreateNewUser";
import React, { useState } from 'react';
import Header from "../Page 1 - LandingPage/Header";
import useViewportHeight from "../../useViewportHeight";

function CreateUserPage() {
    useViewportHeight(); 
    
    const [selectedInput, setSelectedInput] = useState(false);

    const handleClickSection = () => {
        setSelectedInput(true);
    }

    const handleInputClicked = () => {
        setSelectedInput(false);
    }

    return (
        <section className="allGroupCreate" onClick={handleClickSection}>
            <Header hideButton={true} />
            <div>
                <div>
                    <img src="/Images_Login/ImageLoginPage.png" />
                </div>
                <div>
                    <FormsCreateNewUser
                        selectedInput={selectedInput}
                        handleInputClicked={handleInputClicked}
                    />
                </div>
            </div>
        </section>
    )
}

export default CreateUserPage;