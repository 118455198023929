import './ErrorMessage.css';
import { useEffect } from 'react';

function ErrorMessage({ message, messageSetter }) {

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                messageSetter(null);
            }, 3400);
        }
    }, [message]);

    if(!message) return null;

    return (
        <section className='main-container-error-message' >
            <p>{message}</p>
        </section>
    );
}

export default ErrorMessage;
