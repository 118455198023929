import Section5FormAboutUs from "../Section5FormAboutUs"
import "./Section5.css"

const Section5 = () => {

    const rightSectionTopCard = [
        {
            img: "Images_AboutUs/section5socialsunna.svg",
            text: "Sunna",
            backgroundColor: "none"
        },
        {
            img: "Images_AboutUs/section5phone.svg",
            text: "+55 (11) 95306-0008",
            backgroundColor: "#EEC842"
        },
        {
            img: "Images_AboutUs/section5email.svg",
            text: "sunnaintern@gmail.com",
            backgroundColor: "none"
        }
    ]

    const topCard = (item) => {
        return (
            <div key={item.text} style={{ backgroundColor: item.backgroundColor }}>
                <img src={item.img} alt="CardsImage"></img>
                <p>{item.text}</p>
            </div>
        )
    }

    return (
        <section className="Section5">
            <img src="Images_AboutUs/section5leftimg.svg" alt="LeftImg"></img>
            <div>
                <h1>Precisa de ajuda?</h1>
                <h2>Entre em contato com a gente que podemos te ajudar!</h2>
                <h2>Se quiser, pode usar este canal para fazer sugestões também!</h2>
                <div>
                    {Object.values(rightSectionTopCard).map(item => topCard(item))}
                </div>
                <Section5FormAboutUs
                    nameInputted={null}
                    setName={null}
                    emailInputted={null}
                    setEmail={null}
                    messageInputted={null}
                    setMessage={null}
                />
            </div>
        </section>
    )
}

export default Section5;