import { useEffect, useState } from 'react';
import './ImportFinancesWindow.css'
import { useFinancialContext } from '../../../../../../../FinancialContext';
import axios from 'axios';
import ErrorMessage from '../../../../../../GenericComponents/ErrorMessage';

function ImportFinancesWindow({ type, setActivateImportFinances }) {
    const { axiosURL } = useFinancialContext();

    const [csvFile, setCsvFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [selectedImageExampleImport, setSelectedImageExampleImport] = useState(0)

    const [imageToShow, setImageToShow] = useState();
    const [imageWasClicked, setImageWasClicked] = useState(false)

    const [imported, setImported] = useState('');
    const [error_message, setError_message] = useState('');

    const financeType = type === 'income' ? 'suas receitas' : 'seus gastos';
    const financeType2 = type === 'income' ? 'suas receitas' : 'seus gastos';

    const images = [
        "Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/sheetsOne.svg",
        "Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/sheetsTwo.svg",
        "Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/sheetsThree.svg",
        "Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/sheetsFour.svg",
        "Images_Dashboard_Summary/SummaryView/OpenFinancialCard/ImportExamplePics/sheetsFive.svg"
    ]

    const handleClickContainer = (e) => {
        e.stopPropagation();
    }

    const handleImportButtonClick = () => {
        const fileInput = document.getElementById('file-input-import-finance');
        fileInput.click();
    }

    const handleClickedImage = (num) => {
        setSelectedImageExampleImport(num);
        setImageWasClicked(true);
    }

    const renderClickableImages = () => {
        return [0, 1, 2, 3, 4].map((num) =>
            <div className={num === selectedImageExampleImport && 'clickedOptionExampleImage'} onClick={() => handleClickedImage(num)} />
        );
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setFileName(file.name);
        setCsvFile(file); // Certifique-se de que csvFile é um Blob
    };

    const handleFetchImportFinances = async () => {
        try {
            const token = localStorage.getItem("SnnAcss");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const formData = new FormData();
            formData.append('file', csvFile);

            const response = await axios.post(`${axiosURL}/${type}/import`, formData, config);

            if (response.status === 200) {
                setImported("Importado com sucesso!")
                setTimeout(() => {
                    setImported("")
                    window.location.reload();
                }, 1500)
            }

        } catch (error) {

            if (error.response && error.response.status === 400) {
                setError_message(error.response.data);
            }
        }
    };




    useEffect(() => { //Responsável por alterar as imagens do carrossel
        let interval;

        if (imageWasClicked) { //Se o usuário selecionou alguma imagem, ele espera 8 segundos para ir para a próxima ao invés de 3
            setTimeout(() => {
                setImageWasClicked(false);
                setSelectedImageExampleImport(prevSelectedImageExampleImport =>
                    prevSelectedImageExampleImport === 4 ? 0 : prevSelectedImageExampleImport + 1
                );
            }, 7000)
            return;
        }

        interval = setInterval(() => {
            setSelectedImageExampleImport(prevSelectedImageExampleImport =>
                prevSelectedImageExampleImport === 4 ? 0 : prevSelectedImageExampleImport + 1
            );
        }, 4000);

        return () => clearInterval(interval);
    }, [imageWasClicked]);

    useEffect(() => { //Resposável por alterar a imagem a ser visualizada
        setImageToShow(images[selectedImageExampleImport])
    }, [selectedImageExampleImport])

    return (
        <div className='importFinancesMainContainer' onClick={handleClickContainer}>
            <header>
                <p>Importe {financeType} e torne tudo mais fácil!</p>
                <p onClick={() => setActivateImportFinances(false)}>Voltar</p>
            </header>
            <body>
                <section>
                    <header>
                        <p>Passo a passo</p>
                    </header>
                    <ol>
                        <li>Crie uma nova planilha em algum editor de planilhas (<a href='https://docs.google.com/spreadsheets/u/1/?pli=1' target="_blank" rel="noopener noreferrer">Google planilhas</a> ou Excel);</li>
                        <li>Siga o padrão de 3 colunas, com os seguintes nomes:
                            <ul>
                                <li>Coluna 1: data</li>
                                <li>Coluna 2: nome</li>
                                <li>Coluna 3: valor</li>
                            </ul>
                        </li>
                        <li>Insira os valores nas linhas, referente a {financeType2};</li>
                        <li>Clique na coluna e defina o tipo da coluna de data como "data" e de valor como "número";</li>
                        <li>Exporte/Faça o download da sua planilha como um arquivo .csv, importe ela logo abaixo, e clique no botão "Confirmar";</li>
                    </ol>
                </section>
                <section>
                    <header>
                        <p>Exemplo no Google Planilhas</p>
                    </header>
                    <section>
                        <figure>
                            <img src={imageToShow} alt='imgExampleImport' />
                        </figure>
                        <footer>
                            {renderClickableImages()}
                        </footer>
                    </section>
                </section>

            </body>
            <footer>
                <p>
                    <b>Nota:</b> Após importar {financeType}, você poderá editar, separar por categorias, adicionar observações, etc.
                </p>
                <div>
                    <p id='importSuccessful'>{imported}</p>

                    <button onClick={handleImportButtonClick}>
                        <input
                            type='file'
                            id="file-input-import-finance"
                            accept=".csv"
                            onChange={handleFileChange}
                        />
                        <p>Importar</p>
                    </button>
                    <p>{fileName}</p>
                </div>
                <button
                    onClick={handleFetchImportFinances}
                    disabled={!csvFile}
                    style={{
                        backgroundColor: !csvFile && '#242424',
                        opacity: !csvFile && '50%',
                        cursor: !csvFile && 'default'
                    }}
                >Confirmar</button>
            </footer>
            <ErrorMessage
                message={error_message}
                messageSetter={setError_message}
            />
        </div>
    )
}

export default ImportFinancesWindow