import './mainSection.css';
import { useFinancialContext } from '../../FinancialContext';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardSideBar from '../Page 5 - DashBoard/DashboardSideBar';
import HeaderSummary from '../Page 5 - DashBoard/DashboardHeader';

function InvestimentsMainPage({}){
    const { checkTokenValidity } = useFinancialContext();
    const navigate = useNavigate();

    const [userLooged, setUserLogged] = useState(true);

    const [viewClicked, setViewClicked] = useState(false);
    const [selectedView, setSelectedView] = useState('Investimentos');
    const [sideBarSmartPhone, setSideBarSmartPhone] = useState(false);

    const allViews = {
        cateira: {
            title:'Investimentos',
            img: '/Images_Dashboard_Summary/SideBar/investiment.png'
        }
    };

    const lastOptionSideBar = {
        title: 'Finanças',
        img: '/Images_Dashboard_Summary/SideBar/financa.png'
    }

    const handleClickView = () => {
        setViewClicked(true);
        setTimeout(() => {
            setViewClicked(false);
        }, 50)
    }

    const getTitle = () => {
        switch (selectedView) {
            case 'Investimentos':
                return "Meus investimentos"
        }
    }

    const fetchData = async () => {
        try {
            const userLogged = await checkTokenValidity(); 
            setUserLogged(userLogged);
        } catch (error) {
            console.error('Erro na validação do token');
        }
    };
    useEffect(() => {
        fetchData();
    }, []);


    if (userLooged) {
        return (
            <div className="containerInvestiments">
                <aside
                    style={{
                        width: sideBarSmartPhone && "27.5%"
                    }}
                >
                    <DashboardSideBar
                        selectedView={selectedView}
                        handleViewClick={(view) => setSelectedView(view)}
                        allViews={allViews}
                        viewClicked={viewClicked}
                        sideBarSmartPhone={sideBarSmartPhone}
                        setSideBarSmartPhone={setSideBarSmartPhone}
                        lastOptionSideBar={lastOptionSideBar}
                    />
                </aside>

                <div onClick={handleClickView}>
                    <header>
                        <HeaderSummary getTitle={getTitle} viewClicked={viewClicked} hideMonth={true}/>
                    </header>
                    <section>
                        {selectedView === 'Investimentos' && (<>carteira</>)}
                        {selectedView === 'Finanças' && navigate("/dashboard")}
                    </section>
                </div>
            </div>
        )
    } else {
        navigate("/login")
    }
}

export default InvestimentsMainPage;