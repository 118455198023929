import React, { useState, useEffect } from 'react';
import './ToggleSliderSwitch.css';

const ToggleSliderSwitch = ({ onChange, active, width, height, circle, bottom, leftOff, leftOn, cursor, borderRadius, translate }) => {
    const [checked, setChecked] = useState(active);

    const toggleSlider = {
        width: width,
        height: height,
        position: 'relative',
        backgroundColor: checked ? '#65C37A' : '#9A9A9A',
        borderRadius: borderRadius,
        cursor: cursor
    }

    const slider = {
        width: circle,
        height: circle,
        left: checked ? leftOn : leftOff, // Muda a posição baseado no estado
        bottom: bottom,
        position: 'absolute',
        borderRadius: '50%',
        transition: 'transform 0.3s',
        backgroundColor: '#FFFFFF',
        transform: checked ? `translateX(${translate})` : 'translateX(0)',
        cursor: cursor
    }

    const handleToggle = (e) => {
        e.stopPropagation();
        const newValue = !checked;
        setChecked(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    useEffect(() => {
        setChecked(active)
    }, [active])

    return (
        <>
            <div
                style={toggleSlider}
                onClick={handleToggle}
            >
                <label style={slider} onClick={handleToggle}/>
            </div>
            <div className="smartPhoneSwitch toggle-slider" onClick={handleToggle}>
                <input
                    type="checkbox"
                    id="toggle"
                    checked={checked}
                />
                <label htmlFor="toggle" className="slider" />
            </div>
        </>
    );
};

export default ToggleSliderSwitch;