import './Header.css'
import { useNavigate } from 'react-router-dom';

function Header({ hideButton }) {
    const navigate = useNavigate();

    return (
        <div className='MainContainerHeader'>
            <img src='/Images_Dashboard_Summary/SideBar/mainlogosunna.svg' onClick={() => navigate('/')}/>
            {!hideButton && (
                <button onClick={() => navigate('/login')}>
                    <p>Entrar</p>
                </button>
            )}
        </div>
    )
}

export default Header;